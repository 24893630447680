import { ControlButton, MapDropdownMenu } from "../../ui/MapControls";
import { MenuCategory } from "../../ui/Menu";
import { ServerStackIcon } from "@heroicons/react/24/outline";
import { basemaps } from "../../basemaps";
import { useMap } from "../../hooks/mapState";
import { useFloating, offset, shift, autoUpdate } from "@floating-ui/react";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";

interface BasemapSelectorProps {
    basemap: string | number | null;
    onBasemapChange: (basemap: string | number | null) => void;
}

const BasemapSelector = ({
    basemap,
    onBasemapChange,
}: BasemapSelectorProps) => (
    <div className="w-44" key={basemap}>
        <MenuCategory>Basemaps</MenuCategory>
        <div className="text-sm flex flex-col gap-2 p-2 whitespace-nowrap">
            <label className="flex gap-2 items-center">
                <input
                    type="radio"
                    name="basemapSelector"
                    checked={basemap === null}
                    onChange={() => onBasemapChange(null)}
                    className="text-ae-blue-500"
                />
                Default
            </label>
            {Object.entries(basemaps).map(
                ([key, value]) =>
                    value && (
                        <div key={key} className="flex gap-2 items-center">
                            <input
                                id={key}
                                type="radio"
                                name="basemapSelector"
                                checked={basemap === key}
                                onChange={() => onBasemapChange(key)}
                                className="text-ae-blue-500"
                            />
                            <label htmlFor={key}>{value.displayName}</label>
                        </div>
                    ),
            )}
        </div>
    </div>
);

interface BasemapControlProps {
    basemap: string | number | null;
    toggleBasemap: (basemap: string | number | null) => void;
}

export const BasemapControl = (props: BasemapControlProps) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "top-end",
        middleware: [
            offset({
                mainAxis: 2,
                crossAxis: -45,
            }),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button ref={refs.setReference}>
                        <ControlButton
                            title="Change basemap"
                            active={open}
                            className="w-8 rounded"
                        >
                            <FontAwesomeIcon icon={faCog} className="w-4" />
                        </ControlButton>
                    </Popover.Button>
                    <Popover.Panel
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className="bg-white rounded p-2 border border-ae-gray-250 z-50"
                    >
                        <BasemapSelector
                            basemap={props.basemap}
                            onBasemapChange={props.toggleBasemap}
                        />
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
};

export const BasemapControls = () => {
    const { basemap, setMapState } = useMap("mainMap");

    const handleBasemapChange = (newBasemap: string | null) => {
        setMapState((ms) => ({ ...ms, basemap: newBasemap }));
    };

    return (
        <MapDropdownMenu
            button={
                <ControlButton className="w-8">
                    <ServerStackIcon className="w-4" />
                </ControlButton>
            }
        >
            <BasemapSelector
                basemap={basemap}
                onBasemapChange={handleBasemapChange}
            />
        </MapDropdownMenu>
    );
};
