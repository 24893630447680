import { useContext, useMemo, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { MapContext } from "../MapView";
import {
    ArrowDownTrayIcon,
    FunnelIcon,
    PencilIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import { FunnelIcon as SolidFunnelIcon } from "@heroicons/react/24/solid";
import { ControlButton } from "../ui/MapControls";
import { ControlContainer } from "./common";
import DeckGL from "@deck.gl/react";
import Map from "react-map-gl/maplibre";
import { basemaps } from "../basemaps";
import * as turf from "@turf/turf";
import { toKML } from "@placemarkio/tokml";
import { geoJSONToWkt } from "betterknown";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { Modal } from "../../../ui/Modals";
import { SecondaryButton } from "../../../ui/Buttons";

interface DownloadShapeModalProps {
    visible: boolean;
    onClose: () => any;
    drawnShape: any;
}

const DownloadShapeModal = (props: DownloadShapeModalProps) => {
    const { drawnShape } = props;
    const [, copyToClipboard] = useCopyToClipboard();

    const layer = useMemo(() => {
        return new GeoJsonLayer({
            id: "drawnShape",
            data: drawnShape,
            pointType: "circle",
            filled: true,
            getPointRadius: 6,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            stroked: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            getFillColor: [255, 255, 255, 100],
            getLineWidth: 1,
            getLineColor: [0, 0, 0, 255],
        });
    }, [drawnShape]);

    /**
     * Converts shape into WKT and copies it into clipboard
     */
    const copyWKTString = () => {
        if (drawnShape.features.length > 0) {
            const wkt = geoJSONToWkt(drawnShape.features[0].geometry);
            copyToClipboard(wkt);
        }
    };

    /**
     * Dumps `drawShape` contents into a downloadable GeoJSON file.
     */
    const downloadGeoJson = () => {
        const dataStr =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(drawnShape.features[0].geometry));
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "selection.geojson");
        // This is needed for Firefox compatibility.
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    /**
     * Dumps `drawShape` contents into a downloadable KML file.
     */
    const downloadKML = () => {
        const dataStr =
            "data:application/vnd.google-earth.kml+xml;charset=utf-8," +
            encodeURIComponent(toKML(drawnShape));
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", "selection.kml");
        // This is needed for Firefox compatibility.
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    };

    return (
        <Modal size="sm" visible={props.visible} onClose={props.onClose}>
            {/* Animated upload thingy */}
            <div className="ml-2 mt-2 mb-1">
                <div className="h-10 w-10 flex items-center justify-center rounded-full text-ae-blue-500 bg-ae-blue-100">
                    <ArrowDownTrayIcon className="h-6 w-6" />
                </div>
                <div className="relative -mt-10 h-10 w-10 rounded-full border opacity-20 border-ae-blue-900 animate-ping" />
            </div>

            <div className="relative h-56 mb-2 rounded overflow-hidden">
                <DeckGL
                    viewState={
                        {
                            latitude: 39.82,
                            longitude: -98.58,
                            zoom: 2,
                        } as any
                    }
                    layers={[layer]}
                >
                    <Map
                        mapStyle={basemaps.esri.mapStyle as any}
                        attributionControl={false}
                    />
                </DeckGL>
            </div>
            <div className="flex flex-col gap-2">
                <SecondaryButton onClick={downloadKML}>
                    Download KML
                </SecondaryButton>
                <SecondaryButton onClick={downloadGeoJson}>
                    Download GeoJSON
                </SecondaryButton>
                <SecondaryButton onClick={copyWKTString}>
                    Copy WKT string to clipboard
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export const DrawingControl = () => {
    const context = useContext(MapContext);
    const [showModal, setShowModal] = useState(false);

    const {
        drawnShape,
        isDrawing,
        stopDrawing,
        isFiltering,
        setIsFiltering,
        hasShape,
        setSelectedFilters,
    } = context.drawAndFilterControls;
    const areaSqMiles = turf.area(drawnShape) * 3.861e-7;

    if (isDrawing) {
        return (
            <ControlContainer className="gap-2 p-2">
                <div className="px-2 w-full flex items-center justify-center gap-2 italic bg-white rounded p-2">
                    <PencilIcon className="w-4" />
                    <div className="w-20 text-sm">Drawing...</div>
                    <button
                        className="text-sm"
                        onClick={() => stopDrawing(undefined)}
                    >
                        Cancel
                    </button>
                </div>
            </ControlContainer>
        );
    }

    if (hasShape) {
        return (
            <>
                <ControlContainer className="gap-2 p-2">
                    <div>
                        <div className="bg-white rounded p-2 mb-2">
                            <p className="font-semibold text-sm">Selection</p>
                            <div className="text-sm my-1">
                                <p>Area: {areaSqMiles.toFixed(2)} sq mi</p>
                            </div>
                        </div>
                        <div className="flex rounded overflow-hidden divide-x w-fit text-sm">
                            <ControlButton
                                title="Filter points on map"
                                className="w-8 rounded-l"
                                onClick={() => setIsFiltering(!isFiltering)}
                            >
                                {isFiltering ? (
                                    <SolidFunnelIcon className="w-4 text-ae-blue-500" />
                                ) : (
                                    <FunnelIcon className="w-4" />
                                )}
                            </ControlButton>
                            <ControlButton
                                title="Download selection"
                                className="w-8"
                                onClick={() => setShowModal(true)}
                            >
                                <ArrowDownTrayIcon className="w-4" />
                            </ControlButton>
                            <ControlButton
                                title="Clear selection"
                                className="w-8 rounded-r"
                                onClick={() => {
                                    stopDrawing(undefined);
                                    setSelectedFilters([]);
                                }}
                            >
                                <TrashIcon className="w-4" />
                            </ControlButton>
                        </div>
                    </div>
                </ControlContainer>

                {/* Modal to download shape data */}
                <DownloadShapeModal
                    visible={showModal}
                    drawnShape={drawnShape}
                    onClose={() => setShowModal(false)}
                />
            </>
        );
    }

    return null;
};
