import { Combobox, Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useDebounce } from "@uidotdev/usehooks";
import {
    ChangeEvent,
    Fragment,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faChevronDown,
    faChevronsLeft,
    faChevronsRight,
    faXmark,
} from "@fortawesome/pro-light-svg-icons";
import { useFloating, autoUpdate } from "@floating-ui/react";
import { flip, offset, shift, size } from "@floating-ui/dom";
import { Control, useController } from "react-hook-form";
import { InputButton } from "./Buttons";
import { isValidDate } from "../utils/validation";

interface SearchInputProps {
    value: string;
    placeholder: string;
    onChange: (newValue: string) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    className?: string;
    containerClassName?: string;
}

export const SearchInput = (props: SearchInputProps) => {
    return (
        <div
            className={`
            ${props.containerClassName || ""}
            relative h-9
        `}
        >
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5 text-ae-blue-900" />
            </div>
            <input
                type="search"
                id="default-search"
                className={`
                    ${props.className || ""}
                    block ps-10 text-sm rounded-md placeholder:text-ae-gray-300 border border-ae-gray-200
                    w-full
                `}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                onKeyUp={props.onKeyUp}
                autoFocus={props.autoFocus}
            />
        </div>
    );
};

type Item = {
    id: string | number;
    label: string;
};

interface ListboxProps {
    selected: Item[];
    setSelected: (selected: Item[]) => void;
    options: Item[];
}

export const ListBox = (props: ListboxProps) => {
    return (
        <Listbox value={props.selected} onChange={props.setSelected} multiple>
            <Listbox.Button className="w-full rounded p-2 text-left border h-8 border-ae-slate-600 line-clamp-1 whitespace-nowrap">
                {props.selected.map((i) => i.label).join(", ")}
            </Listbox.Button>
            <div className="relative text-sm">
                <Listbox.Options className="top-1 z-0 flex flex-col absolute bg-white rounded w-full border border-ae-slate-600 drop-shadow-md">
                    {props.options.map((i) => (
                        <Listbox.Option
                            className="p-2 flex cursor-pointer bg-white rounded hover:bg-neutral-100"
                            key={i.id}
                            value={i}
                        >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`flex-grow ${
                                            selected
                                                ? "font-medium"
                                                : "font-normal"
                                        }`}
                                    >
                                        {i.label}
                                    </span>
                                    {selected ? (
                                        <CheckIcon className="h-5 mr-2" />
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

interface FilteringDropdownProps {
    value: number | string | null;
    onChange: (value: number | string) => void;
    emptyValue?: string;
    options: {
        id: string | number;
        displayName: string;
        searchString: string;
    }[];
    extraActions?: {
        icon: ReactNode;
        title: string;
        action: () => void;
    }[];
}

export const FilteringDropdown = (props: FilteringDropdownProps) => {
    const [query, setQuery] = useState("");

    const filteredOptions =
        query === ""
            ? props.options
            : props.options.filter((option) =>
                  option.searchString.includes(query.toLowerCase()),
              );

    return (
        <Combobox
            value={
                props.options.find((option) => option.id === props.value) ||
                null
            }
            onChange={(p) => props.onChange(p?.id || null)}
        >
            <div className="relative mt-1">
                <Combobox.Input
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Type to search"
                    displayValue={(option: any) =>
                        option ? option.displayName : props.emptyValue
                    }
                    className="text-sm rounded w-full relative pr-16"
                />

                <div className="absolute inset-y-0 right-0 flex items-center">
                    {props.extraActions &&
                        props.extraActions.map((action, idx) => (
                            <button
                                key={idx}
                                className="flex items-center p-1"
                                title={action.title}
                                onClick={(e) => {
                                    action.action();
                                    e.preventDefault();
                                }}
                            >
                                {action.icon}
                            </button>
                        ))}

                    <Combobox.Button className="flex items-center p-1 pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Combobox.Options className="absolute mt-1 z-10 rounded overflow-hidden border border-gray-300 w-full">
                    {props.emptyValue && (
                        <Combobox.Option value={null} as={Fragment}>
                            {({ active, selected }) => (
                                <li
                                    className={`
                                    p-2 text-sm cursor-pointer
                                    flex items-center gap-2
                                    ${
                                        active
                                            ? "bg-ae-blue-500 text-white"
                                            : "bg-white text-black"
                                    }
                                `}
                                >
                                    {selected && <CheckIcon className="w-4" />}
                                    {props.emptyValue}
                                </li>
                            )}
                        </Combobox.Option>
                    )}
                    {filteredOptions.map((option) => (
                        <Combobox.Option
                            key={option.id}
                            value={option}
                            as={Fragment}
                        >
                            {({ active, selected }) => (
                                <li
                                    className={`
                                    p-2 text-sm cursor-pointer
                                    flex items-center gap-2
                                    ${
                                        active
                                            ? "bg-ae-blue-500 text-white"
                                            : "bg-white text-black"
                                    }
                                `}
                                >
                                    {selected && <CheckIcon className="w-4" />}
                                    {option.displayName}
                                </li>
                            )}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

export const ValidatingDateField = ({
    value,
    onChange,
}: {
    value?: Date;
    onChange: (v: Date) => void;
}) => {
    const [inputValue, setInputValue] = useState(
        value ? value.toISOString().split("T")[0] : "",
    );
    const [error, setError] = useState(false);
    const debouncedInputValue = useDebounce(inputValue, 300);

    // If value changes, update internal state
    useEffect(() => {
        setInputValue(value ? value.toISOString().split("T")[0] : "");
    }, [value]);

    // Check if the debounced input value is a valid date
    useEffect(() => {
        const isValidDate = (dateString) => {
            const date = new Date(dateString);
            return !isNaN(date.getTime()) && date.getFullYear() > 1950;
        };

        if (inputValue === "") {
            setError(false);
            return;
        }

        if (isValidDate(debouncedInputValue)) {
            setError(false);
            if (
                !value ||
                debouncedInputValue !== value.toISOString().split("T")[0]
            ) {
                onChange(new Date(debouncedInputValue));
            }
        } else {
            setError(true);
        }
    }, [debouncedInputValue]); // Adding `onChange` here causes issues (infinite setstate loop)

    return (
        <>
            <input
                type="date"
                className={`rounded p-1 text-sm w-full ${
                    error && "border border-red-500"
                }`}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
            />
            {error && <span className="text-red-500">Invalid date</span>}
        </>
    );
};

interface NumberFieldProps {
    value: number | undefined;
    onChange: (value: number | undefined) => void;
    min?: number;
    max?: number;
    placeholder?: string;
}

export const NumberField = ({
    value,
    onChange,
    min,
    max,
    placeholder,
}: NumberFieldProps) => {
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (inputValue === "") {
            onChange(undefined);
            setError(null);
        } else {
            const numValue = Number(inputValue);
            if (isNaN(numValue)) {
                setError("Please enter a valid number");
            } else if (min !== undefined && numValue < min) {
                setError(`Value must be greater than or equal to ${min}`);
            } else if (max !== undefined && numValue > max) {
                setError(`Value must be less than or equal to ${max}`);
            } else {
                onChange(numValue);
                setError(null);
            }
        }
    };

    return (
        <div>
            <input
                type="number"
                className={`rounded p-1 text-sm w-full ${
                    error && "border border-red-500"
                }`}
                value={value ?? ""}
                onChange={handleChange}
                min={min}
                max={max}
                placeholder={placeholder}
            />
            {error && (
                <p className="text-red-500 text-xs mt-1 break-before-auto">
                    {error}
                </p>
            )}
        </div>
    );
};

interface RangeFieldProps {
    valueMin?: string;
    valueMax?: string;
    onChangeMin: (value?: string) => void;
    onChangeMax: (value?: string) => void;
    units?: string;
    className?: string;
}

export const RangeField = (props: RangeFieldProps) => {
    return (
        <div className="group flex border border-ae-gray-250 rounded items-center bg-white whitespace-nowrap h-8">
            <div className="px-3 text-neutral-500">From</div>
            <input
                className={`
                    px-2 h-full text-sm w-full
                    border-0 focus:ring-1 focus:ring-inset ring-ae-blue-550
                    ${false && "ring-1 ring-inset ring-red-500"}
                    ${props.className}
                `}
                value={props.valueMin}
                onChange={(e) => props.onChangeMin(e.target.value)}
            />
            {props.units && <div className="px-3">{props.units}</div>}
            <div className="h-8 px-3 flex items-center justify-center">
                <FontAwesomeIcon
                    icon={faArrowRight}
                    className="w-4 text-neutral-500"
                />
            </div>
            <div className="px-3 text-neutral-500">To</div>
            <input
                className={`
                    px-3 h-full text-sm w-full 
                    border-0 focus:ring-1 focus:ring-inset ring-ae-blue-550
                    ${false && "ring-1 ring-inset ring-red-500"}
                    ${props.className}
                `}
                value={props.valueMax}
                onChange={(e) => props.onChangeMax(e.target.value)}
            />
            {props.units && <div className="px-3">{props.units}</div>}
        </div>
    );
};

interface RangeFormFieldProps {
    minFieldName: string;
    maxFieldName: string;
    units?: string;
    control: Control<any>;
}

export const RangeFormField = ({
    minFieldName,
    maxFieldName,
    control,
    units,
}: RangeFormFieldProps) => {
    const { field: minField } = useController({ name: minFieldName, control });
    const { field: maxField } = useController({ name: maxFieldName, control });
    return (
        <RangeField
            valueMin={minField.value}
            onChangeMin={(v) => minField.onChange(v)}
            valueMax={maxField.value}
            onChangeMax={(v) => maxField.onChange(v)}
            units={units}
        />
    );
};

export const ValidatingDateInput = ({
    value,
    onChange,
    className,
}: {
    value?: string;
    onChange: (v: string) => void;
    className?: string;
}) => {
    const [inputValue, setInputValue] = useState(
        value ? value.split("T")[0] : "",
    );
    const [error, setError] = useState(false);

    // If value changes, update internal state
    useEffect(() => {
        setInputValue(value ? value.split("T")[0] : "");
    }, [value]);

    const pushChanges = () => {
        if (inputValue === "") {
            setError(false);
            onChange(undefined);
            return;
        }

        if (isValidDate(inputValue)) {
            setError(false);
            // Only callback if the date changed to avoid re-renders from bubbling up.
            if (
                new Date(inputValue).toISOString().split("T")[0] !==
                value?.split("T")[0]
            ) {
                onChange(new Date(inputValue).toISOString());
            }
        } else {
            setError(true);
        }
    };

    return (
        <>
            <input
                type="date"
                className={`
                    py-1 px-2 text-sm w-full border focus:ring-1 focus:ring-inset ring-ae-blue-550
                    bg-white group-hover:bg-ae-gray-100 border-ae-gray-250 
                    ${error && "ring-1 ring-inset ring-red-500"}
                    ${className}
                `}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
                onBlur={pushChanges}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        pushChanges();
                    }
                }}
                title={
                    error
                        ? "Invalid date: dates need to be valid number and year > 1999."
                        : undefined
                }
            />
        </>
    );
};

interface DateRangeFieldProps {
    valueAfter?: string;
    valueBefore?: string;
    onChangeAfter: (value?: string) => void;
    onChangeBefore: (value?: string) => void;
    className?: string;
    showPresets?: boolean;
    showRangeNavigation?: boolean;
}

export const DateRangeField = (props: DateRangeFieldProps) => {
    // Add state to track invalid range
    const [isInvalidRange, setIsInvalidRange] = useState(false);

    /**
     * Compute number of approximate months between the two dates.
     *
     * This is used to highlight the range shortcut buttons at the
     * top, even if the dates are a little bit off.
     */
    const monthsBetweenDates = useMemo(() => {
        const d1 = new Date(props.valueAfter).getTime();
        const d2 = new Date(props.valueBefore).getTime();

        // Check if range is invalid (From date is after To date)
        const isInvalid = d1 > d2;
        setIsInvalidRange(isInvalid);

        if (isInvalid) {
            return 0;
        }

        // Calculate the difference in milliseconds
        const difference = Math.abs(d2 - d1);

        // Convert the difference to months
        const months = difference / (1000 * 60 * 60 * 24 * 30);

        // Round to the nearest integer and return
        return Math.round(months);
    }, [props.valueBefore, props.valueAfter]);

    /**
     * Update date ranges (used by presets).
     */
    const updateRange = (months: number) => {
        props.onChangeAfter(
            new Date(
                new Date().setMonth(new Date().getMonth() - months),
            ).toISOString(),
        );
        props.onChangeBefore(new Date().toISOString());
    };

    /**
     * Used by the << and >> buttons to move back and forward
     * between time periods.
     *
     * Eg: operators select a range, like 1 year and then navigate
     * "in time" in 1 year slices of data.
     */
    const moveNPeriods = (periodsToMove: number) => {
        const d1 = new Date(props.valueAfter).getTime();
        const d2 = new Date(props.valueBefore).getTime();
        const difference = Math.abs(d2 - d1);
        props.onChangeAfter(
            new Date(d1 + periodsToMove * difference).toISOString(),
        );
        props.onChangeBefore(
            new Date(d2 + periodsToMove * difference).toISOString(),
        );
    };

    return (
        <div className="flex border border-ae-gray-250 rounded items-center bg-white whitespace-nowrap h-8">
            {props.showRangeNavigation && (
                <InputButton
                    className="px-3 border-0 border-y h-8 rounded-l border-r"
                    onClick={() => moveNPeriods(-1)}
                >
                    <FontAwesomeIcon icon={faChevronsLeft} />
                </InputButton>
            )}
            {props.showPresets && (
                <div className="hidden xl:flex">
                    <InputButton
                        className="px-3 border-0 border-y h-8 border-r"
                        onClick={() => updateRange(1)}
                        active={monthsBetweenDates == 1}
                    >
                        1m
                    </InputButton>
                    <InputButton
                        className="px-3 border-0 border-y h-8 border-r"
                        onClick={() => updateRange(3)}
                        active={monthsBetweenDates == 3}
                    >
                        3m
                    </InputButton>
                    <InputButton
                        className="px-3 border-0 border-y h-8 border-r"
                        onClick={() => updateRange(6)}
                        active={monthsBetweenDates == 6}
                    >
                        6m
                    </InputButton>
                    <InputButton
                        className="px-3 border-0 border-y h-8 border-r"
                        onClick={() => updateRange(12)}
                        active={monthsBetweenDates == 12}
                    >
                        1y
                    </InputButton>
                </div>
            )}
            <div
                className={`flex items-center border-2 ${
                    isInvalidRange ? "border-red-600" : "border-transparent"
                }`}
            >
                <div className="px-3 text-neutral-500">From</div>
                <ValidatingDateInput
                    className="border-0 border-y h-8"
                    value={props.valueAfter}
                    onChange={props.onChangeAfter}
                />
                <div className="h-8 px-3 flex items-center justify-center">
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className="w-4 text-neutral-500"
                    />
                </div>
                <div className="px-3 text-neutral-500">To</div>
                <ValidatingDateInput
                    className="border-0 border-y h-8"
                    value={props.valueBefore}
                    onChange={props.onChangeBefore}
                />
            </div>
            {props.showRangeNavigation && (
                <InputButton
                    className="px-3 border-0 border-y h-8 rounded-r border-l"
                    onClick={() => moveNPeriods(1)}
                    disabled={
                        new Date().getTime() <
                        new Date(props.valueBefore).getTime()
                    }
                >
                    <FontAwesomeIcon icon={faChevronsRight} />
                </InputButton>
            )}
        </div>
    );
};

interface DateRangeFormFieldProps {
    afterFieldName: string;
    beforeFieldName: string;
    control: Control<any>;
    showPresets?: boolean;
}

export const DateRangeFormField = ({
    afterFieldName,
    beforeFieldName,
    control,
    showPresets,
}: DateRangeFormFieldProps) => {
    const { field: afterField } = useController({
        name: afterFieldName,
        control,
    });
    const { field: beforeField } = useController({
        name: beforeFieldName,
        control,
    });
    return (
        <DateRangeField
            valueAfter={afterField.value}
            onChangeAfter={(v) => afterField.onChange(v)}
            valueBefore={beforeField.value}
            onChangeBefore={(v) => beforeField.onChange(v)}
            showPresets={showPresets}
        />
    );
};

interface MultipleChoicePickerProps {
    value?: (string | number)[];
    onChange: (value?: (number | string)[]) => void;
    options: {
        id: string | number;
        label: string;
    }[];
    placeholder?: string;
}

export const MultipleChoicePicker = (props: MultipleChoicePickerProps) => {
    const [query, setQuery] = useState("");
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "bottom-start",
        middleware: [
            offset({
                mainAxis: 4,
            }),
            shift(),
            flip(),
            // Set the floating element size to the container size.
            // This is cool!
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                    });
                },
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const filteredOptions =
        query === ""
            ? props.options
            : props.options.filter((option) => {
                  return option.label
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });

    return (
        <Combobox value={props.value} onChange={props.onChange} multiple>
            <div
                ref={refs.setReference}
                className="text-sm rounded flex justify-between border border-ae-gray-250 bg-white items-center px-2 focus-within:ring-2 ring-ae-blue-550"
            >
                <div className="flex flex-wrap gap-1 py-1 w-full">
                    {props.value?.map((id) => (
                        <button
                            type="button"
                            className="flex text-ae-blue-650 border border-ae-blue-80 bg-ae-blue-50 items-center rounded h-5 px-1 capitalize gap-1  whitespace-nowrap"
                            onClick={() => {
                                props.onChange(
                                    props.value.filter((i) => i !== id),
                                );
                            }}
                        >
                            {props.options.find((item) => item.id === id).label}
                            <FontAwesomeIcon
                                icon={faXmark}
                                className="w-3 h-3"
                            />
                        </button>
                    ))}
                    <Combobox.Input
                        className="border-0 p-0 focus:ring-0 text-sm flex-grow"
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder={
                            !props.value || props.value.length === 0
                                ? props.placeholder
                                : undefined
                        }
                    />
                </div>
                <Combobox.Button className="px-2" type="button">
                    <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3" />
                </Combobox.Button>
            </div>
            <Combobox.Options
                ref={refs.setFloating}
                style={floatingStyles}
                className="bg-white rounded p-0.5 drop-shadow-lg z-50 max-h-56 overflow-scroll"
            >
                {filteredOptions.map((option) => (
                    <Combobox.Option
                        className={`
                            w-full px-4 py-2 rounded capitalize mb-0.5 text-sm cursor-pointer
                            ${
                                props.value &&
                                props.value.findIndex(
                                    (i) => i === option.id,
                                ) !== -1
                                    ? "bg-neutral-100"
                                    : "hover:text-ae-blue-650 hover:bg-ae-blue-50"
                            }
                        `}
                        key={option.id}
                        value={option.id}
                    >
                        {option.label}
                    </Combobox.Option>
                ))}
            </Combobox.Options>
        </Combobox>
    );
};
