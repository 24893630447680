import { useEffect } from "react";
import { Navigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ROUTES } from "../../routes";
import { logInWithSession } from "../../store/auth";
import { useSearchParams } from "react-router-dom";

export const SSOComplete = () => {
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        dispatch(logInWithSession());
    }, [dispatch]);

    const nextParam = searchParams.get("next");

    // Redirect to dashboard if logged in
    if (isLoggedIn) {
        let nextPage = ROUTES.ROOT;
        if (nextParam) {
            nextPage = nextParam;
        }
        return <Navigate to={nextPage} />;
    }

    return <noscript />;
};
