import axios from "axios";
import cookie from "js-cookie";
import {
    Configuration,
    AccountsApi as _AccountsApi,
    AdminApi as _AdminApi,
    AuthApi as _AuthApi,
    DatauploadApi as _DatauploadApi,
    DatadownloadsApi as _DatadownloadsApi,
    EmissionRecordsApi as _EmissionRecordsApi,
    EpaSepApi as _EpaSepApi,
    InfrastructureApi as _InfrastructureApi,
    NondetectsApi as _NondetectsApi,
    MapApi as _MapApi,
    EmissioneventsApi as _EmissioneventsApi,
    RootcausesApi as _RootcausesApi,
    DataProvidersApi as _DataProvidersApi,
    DataImportApi as _DataImportApi,
    NotificationsApi as _NotificationsApi,
    DataPointsApi as _DataPointsApi,
    GeodataApi as _GeodataApi,
} from "./generated";
import { BACKEND_URL } from "../constants/globals";
import { ROUTES } from "../routes";

const getApiConfig = () => {
    const config = {
        basePath: BACKEND_URL,
        apiKey: undefined,
        credentials: "include" as RequestCredentials,
        // Using middleware to intercept 401 responses and redirect to login page.
        middleware: [
            {
                pre: async (context) => {
                    const { url, init } = context;
                    if (
                        ["DELETE", "PATCH", "POST", "PUT"].includes(init.method)
                    ) {
                        const headers = new Headers(init.headers);
                        headers.set("X-CSRFToken", cookie.get("csrftoken"));
                        return {
                            url,
                            init: {
                                ...init,
                                headers,
                            },
                        };
                    }
                },
            },
            {
                post: async (context) => {
                    if (
                        context.response.status === 401 &&
                        !window.location.pathname.includes("/login")
                    ) {
                        const pathWithQueryAndHash =
                            window.location.pathname +
                            window.location.search +
                            window.location.hash;
                        // Redirect to the login page with the 'next' parameter set to the current URL
                        window.location.href = `/login?next=${encodeURIComponent(
                            pathWithQueryAndHash,
                        )}`;
                    }
                },
            },
            // Using middleware to intercept 403 responses caused by ip not whitelisted and
            // redirect to ip not whitelisted page.
            {
                post: async (context) => {
                    if (context.response.status === 403) {
                        const text = await context.response.text();
                        if (
                            text ===
                            "Access denied: Your IP is not whitelisted."
                        ) {
                            window.location.href = ROUTES.IP_NOT_WHITELISTED;
                        }
                    }
                },
            },
        ],
    };

    return new Configuration(config);
};

export const AccountsApi = () => {
    return new _AccountsApi(getApiConfig());
};

export const AuthApi = () => {
    return new _AuthApi(getApiConfig());
};

export const DatauploadApi = () => {
    return new _DatauploadApi(getApiConfig());
};

export const DatadownloadsApi = () => {
    return new _DatadownloadsApi(getApiConfig());
};

export const EmissionRecordsApi = () => {
    return new _EmissionRecordsApi(getApiConfig());
};

export const InfrastructureApi = () => {
    return new _InfrastructureApi(getApiConfig());
};

export const NondetectsApi = () => {
    return new _NondetectsApi(getApiConfig());
};

export const MapApi = () => {
    return new _MapApi(getApiConfig());
};

export const EmissionEventsApi = () => {
    return new _EmissioneventsApi(getApiConfig());
};

export const EpaSepApi = () => {
    return new _EpaSepApi(getApiConfig());
};

export const RootCausesApi = () => {
    return new _RootcausesApi(getApiConfig());
};

export const DataProvidersApi = () => {
    return new _DataProvidersApi(getApiConfig());
};

export const DataImportApi = () => {
    return new _DataImportApi(getApiConfig());
};

export const NotificationsApi = () => {
    return new _NotificationsApi(getApiConfig());
};

export const DataPointsApi = () => {
    return new _DataPointsApi(getApiConfig());
};

export const AdminApi = () => {
    return new _AdminApi(getApiConfig());
};

export const GeodataApi = () => {
    return new _GeodataApi(getApiConfig());
};
