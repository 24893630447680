import { useDebounce } from "@uidotdev/usehooks";
import { useCallback, useMemo } from "react";
import {
    PipelineProductEnum,
    PipelineTypeEnum,
    PipelineMapList,
    InfraTypeEnum,
    InfrastructureMapList,
    AerialImagesList,
    MapPipelinesListRequest,
} from "../../../apiClient/generated";
import { useMapApiClient } from "../../../hooks";
import { useMapDataLoader } from "./dataLoader";
import { useQuery } from "@tanstack/react-query";
import { useMap } from "./mapState";
import {
    AerialImagesLayer,
    InfrastructureLayer,
    PipelineV2Layers,
} from "../layers/infrastructure";
import {
    MAP_INFRA_CLUSTERING_LIMIT,
    MAP_ZOOM_SHOW_DETAILS,
} from "../constants";
import Supercluster from "supercluster";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import type { Feature } from "geojson";
import { cleanFilters } from "./utils";
import { FilterByAreaDef } from "../hooks";

export const usePipelinesOnMap = (
    enabled: boolean,
    pipelineProduct: PipelineProductEnum[],
    pipelineType: PipelineTypeEnum[],
    filterByArea?: FilterByAreaDef,
) => {
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);
    const apiClient = useMapApiClient();
    const debouncedProduct = useDebounce(pipelineProduct, 400);
    const debouncedPipelineType = useDebounce(pipelineType, 400);

    // Retrieve overview data
    const overviewDataLoader = useQuery({
        queryKey: [
            "pipelineOverview",
            debouncedProduct,
            debouncedPipelineType,
            filterByArea,
        ],
        queryFn: async () => {
            return await apiClient.mapPipelinesOverviewsRetrieve({
                pipelineProduct:
                    debouncedProduct.length > 0 ? debouncedProduct : undefined,
                pipelineType:
                    debouncedPipelineType.length > 0
                        ? debouncedPipelineType
                        : undefined,
                locationWithin:
                    filterByArea && !filterByArea.selectedFilters
                        ? JSON.stringify(filterByArea.drawnShape)
                        : undefined,
                locationPreset:
                    filterByArea && filterByArea.selectedFilters
                        ? filterByArea.selectedFilters
                        : undefined,
                lengthMin: 5000,
            });
        },
        enabled,
        refetchOnWindowFocus: false,
        staleTime: 3600 * 24,
    });

    // Pipeline data
    const veryCoarseDataLoader = useMapDataLoader<PipelineMapList>({
        fetchFn: useCallback(
            (areaToFetch, abortSignal, cursor) => {
                return apiClient.mapPipelinesList(
                    {
                        pipelineProduct: debouncedProduct,
                        pipelineType: debouncedPipelineType,
                        lengthMin: 5000,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [debouncedProduct, debouncedPipelineType, filterByArea, apiClient],
        ),
        zoomToStartFetching: 10,
        enabled,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });
    const coarseDataLoader = useMapDataLoader<PipelineMapList>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapPipelinesListRequest>({
                        pipelineProduct: debouncedProduct,
                        pipelineType: debouncedPipelineType,
                    });
                if (shortCircuit) {
                    return { results: [] };
                }
                return await apiClient.mapPipelinesList(
                    {
                        ...cleanFilterValues,
                        lengthMin: 2000,
                        lengthMax: 5000,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [debouncedProduct, debouncedPipelineType, filterByArea, apiClient],
        ),
        zoomToStartFetching: 10,
        enabled: enabled && !veryCoarseDataLoader.loading,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });
    const detailsDataLoader = useMapDataLoader<PipelineMapList>({
        fetchFn: useCallback(
            async (areaToFetch, abortSignal, cursor) => {
                const { cleanFilterValues, shortCircuit } =
                    cleanFilters<MapPipelinesListRequest>({
                        pipelineProduct: debouncedProduct,
                        pipelineType: debouncedPipelineType,
                    });
                if (shortCircuit) {
                    return { results: [] };
                }
                return await apiClient.mapPipelinesList(
                    {
                        ...cleanFilterValues,
                        lengthMax: 2000,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [debouncedProduct, debouncedPipelineType, filterByArea, apiClient],
        ),
        zoomToStartFetching: 13,
        // Always let the coarse data loader run first
        enabled: enabled && !coarseDataLoader.loading,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });

    const layers = useMemo(() => {
        return PipelineV2Layers(
            currentZoom >= MAP_ZOOM_SHOW_DETAILS
                ? [
                      ...veryCoarseDataLoader.data,
                      ...coarseDataLoader.data,
                      ...detailsDataLoader.data,
                  ]
                : [...veryCoarseDataLoader.data, ...coarseDataLoader.data],
            overviewDataLoader.data,
            enabled,
            currentZoom,
        );
    }, [
        currentZoom,
        enabled,
        coarseDataLoader.data,
        veryCoarseDataLoader.data,
        detailsDataLoader.data,
        overviewDataLoader.data,
    ]);

    return {
        loading:
            detailsDataLoader.loading ||
            coarseDataLoader.loading ||
            (overviewDataLoader.isLoading && overviewDataLoader.isFetching),
        overviews: enabled ? overviewDataLoader.data : {},
        layers,
    };
};

/**
 * Infrastructure data: custom hook that loads infrastructure-related
 * data (points, geometries and aerial images) based on filter values.
 *
 * Sites and other equipment are returned separately since they are
 * rendered and fetched in different zoom levels.
 */
export const useInfrastructureOnMap = (
    enabled: boolean,
    infraTypes: InfraTypeEnum[],
    enableOverviews: boolean,
    filterByArea?: FilterByAreaDef,
    dateRangeFilter?: {
        start?: Date;
        end?: Date;
    },
    selectedInfrastructure?: string,
) => {
    const apiClient = useMapApiClient();
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);

    // Retrieve overview data
    const overviewDataLoader = useQuery({
        queryKey: ["infraCoarseOverview", infraTypes, filterByArea],
        queryFn: async () => {
            return await apiClient.mapInfrastructureOverviewsRetrieve({
                boxSize: 150,
                locationWithin:
                    filterByArea && !filterByArea.selectedFilters
                        ? JSON.stringify(filterByArea.drawnShape)
                        : undefined,
                locationPreset:
                    filterByArea && filterByArea.selectedFilters
                        ? filterByArea.selectedFilters
                        : undefined,
            });
        },
        enabled: enabled && infraTypes.length > 0 && enableOverviews,
        refetchOnWindowFocus: false,
        staleTime: 3600 * 12,
    });
    const fineOverviewDataLoader = useQuery({
        queryKey: ["infraOverview", infraTypes, filterByArea],
        queryFn: async () => {
            return await apiClient.mapInfrastructureOverviewsRetrieve({
                boxSize: 60,
                locationWithin:
                    filterByArea && !filterByArea.selectedFilters
                        ? JSON.stringify(filterByArea.drawnShape)
                        : undefined,
                locationPreset:
                    filterByArea && filterByArea.selectedFilters
                        ? filterByArea.selectedFilters
                        : undefined,
            });
        },
        enabled:
            enabled &&
            // !overviewDataLoader.isLoading &&
            infraTypes.length > 0 &&
            enableOverviews,
        refetchOnWindowFocus: false,
        staleTime: 3600 * 24,
    });

    // Load sites first
    const siteDataLoader = useMapDataLoader<InfrastructureMapList>({
        fetchFn: useCallback(
            (areaToFetch, abortSignal, cursor) => {
                return apiClient.mapInfrastructureList(
                    {
                        infraType: ["SITE"],
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [apiClient, filterByArea],
        ),
        zoomToStartFetching: enableOverviews ? 7.9 : 1,
        enabled: enabled,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });

    const equipmentDataLoader = useMapDataLoader<InfrastructureMapList>({
        fetchFn: useCallback(
            (areaToFetch, abortSignal, cursor) => {
                return apiClient.mapInfrastructureList(
                    {
                        infraType: [
                            InfraTypeEnum.EquipmentGroup,
                            InfraTypeEnum.Equipment,
                        ],
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [apiClient, filterByArea],
        ),
        zoomToStartFetching: enableOverviews ? 7.9 : 1,
        enabled: enabled,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });

    // Aerial images data first
    const aerialImagesDataLoader = useMapDataLoader<AerialImagesList>({
        fetchFn: useCallback(
            (areaToFetch, abortSignal, cursor) => {
                return apiClient.mapAerialImagesList(
                    {
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        locationPreset:
                            filterByArea && filterByArea.selectedFilters
                                ? filterByArea.selectedFilters
                                : undefined,
                        takenAtDataRangeAfter: dateRangeFilter.start
                            ? dateRangeFilter.start
                            : undefined,
                        takenAtDataRangeBefore: dateRangeFilter.end
                            ? dateRangeFilter.end
                            : undefined,
                        infrastructure: [selectedInfrastructure],
                        // Only retrieve one image per site (latest)
                        uniquePerSite: true,
                        cursor,
                    },
                    {
                        signal: abortSignal,
                    },
                );
            },
            [apiClient, filterByArea, dateRangeFilter, selectedInfrastructure],
        ),
        zoomToStartFetching: 12,
        enabled: enabled && infraTypes.length > 0 && !!selectedInfrastructure,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter:
            filterByArea && !filterByArea.selectedFilters
                ? filterByArea.drawnShape
                : undefined,
    });

    const clusterData = useMemo(() => {
        const index = new Supercluster({ radius: 50, maxZoom: 11 });
        index.load(
            siteDataLoader.data.map(
                (i) =>
                    ({
                        type: "Feature",
                        geometry: i.location,
                        properties: i,
                    }) as any,
            ),
        );
        return index;
    }, [siteDataLoader.data]);

    const clusteredPoints = useMemo(() => {
        if (!areaOnScreen) {
            return [];
        }

        if (currentZoom > MAP_INFRA_CLUSTERING_LIMIT) {
            return [];
        }

        // Annotate zoom level in which cluster is broken down.
        return clusterData
            .getClusters([-180, -90, 180, 90], currentZoom)
            .map((i) => {
                if (i.properties.cluster) {
                    i.properties.zoom = clusterData.getClusterExpansionZoom(
                        i.properties.cluster_id,
                    );
                    i.properties.tooltip = "Click to zoom in";
                }
                return i;
            });
    }, [clusterData, currentZoom, areaOnScreen]);

    // Memoize items shown on map based on loaded data,
    // zoom level and filter parameters.
    const infrastructureLayers = useMemo(() => {
        let pointsToShow: InfrastructureMapList[] = [];
        if (infraTypes.includes("SITE")) {
            if (currentZoom > MAP_ZOOM_SHOW_DETAILS) {
                pointsToShow = [...siteDataLoader.data];
            } else {
                pointsToShow = [
                    ...siteDataLoader.data.map((i) => ({
                        ...i,
                        shape: undefined,
                    })),
                ];
            }
        }
        if (infraTypes.includes("EQUIPMENT")) {
            pointsToShow = [...pointsToShow, ...equipmentDataLoader.data];
        }
        let data: Feature[] = createFeatureCollection(pointsToShow).features;
        if (enableOverviews && currentZoom < MAP_INFRA_CLUSTERING_LIMIT) {
            data = clusteredPoints;
        }

        return [
            InfrastructureLayer(
                data,
                currentZoom > 3.5 && !fineOverviewDataLoader.isLoading
                    ? fineOverviewDataLoader.data
                    : overviewDataLoader.data,
                enabled && infraTypes.length > 0,
                currentZoom,
                undefined,
                enableOverviews,
            ),
        ];
    }, [
        infraTypes,
        enabled,
        enableOverviews,
        siteDataLoader.data,
        clusteredPoints,
        currentZoom,
        equipmentDataLoader.data,
        overviewDataLoader.data,
        fineOverviewDataLoader.data,
        fineOverviewDataLoader.isLoading,
    ]);

    const aerialImageLayers = useMemo(() => {
        return [
            AerialImagesLayer(
                aerialImagesDataLoader.data,
                enabled,
                currentZoom,
            ),
        ];
    }, [enabled, aerialImagesDataLoader.data, currentZoom]);

    // Return data
    return {
        loading:
            equipmentDataLoader.loading ||
            siteDataLoader.loading ||
            aerialImagesDataLoader.loading ||
            (overviewDataLoader.isLoading && enabled) ||
            (fineOverviewDataLoader.isLoading && enabled),
        infrastructureLayers,
        aerialImageLayers,
    };
};
