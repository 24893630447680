import { useEffect } from "react";
import { InfrastructureImport } from "../../apiClient/generated";
import { useInfrastructureApiClient } from "../../hooks";
import { Card } from "../../ui/Card";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";

interface WaitingAntivirusCheckProps {
    importData: InfrastructureImport;
    refresh?: () => void;
}

export const WaitingAntivirusCheck = (props: WaitingAntivirusCheckProps) => {
    const { importData, refresh } = props;
    const apiClient = useInfrastructureApiClient();

    const importDataQuery = useQuery({
        queryKey: ["infrastructureImport", importData.id],
        queryFn: async () => {
            return await apiClient.infrastructureImportRetrieve({
                id: importData.id,
            });
        },
        enabled: importData && importData.importStatus === "AV_CHECK",
        refetchInterval: 2000,
    });

    useEffect(() => {
        if (importDataQuery.data) {
            if (importDataQuery.data.importStatus !== "AV_CHECK") {
                refresh();
            }
        }
    }, [importDataQuery, refresh]);

    return (
        <Card>
            <p className="w-full flex justify-center">
                <MagnifyingGlassIcon className="w-20 h-20" />
                <MagnifyingGlassIcon className="absolute w-20 h-20 animate-ping opacity-10" />
            </p>

            <p className="w-full flex justify-center">
                Waiting antivirus check...
            </p>

            <p className="w-full flex justify-center text-sm">
                Check back in a few minutes, or
                <button className="ml-1 text-blue-800" onClick={props.refresh}>
                    refresh status now
                </button>
                .
            </p>
        </Card>
    );
};
