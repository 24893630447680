import { EmissionLayerStyle } from "./layers/emissions";
import { generateIconAtlas } from "./utils/helpers";

export const EMISSION_COLORS: {
    [key: string]: [number, number, number];
} = {
    thirdParty: [0, 124, 145],
    thirdPartyPublic: [0, 62, 73],
    operatorProvided: [154, 205, 50],
    epa: [106, 13, 173],
    epaPublic: [56, 0, 92],
};

export const CROSSHAIR_ICONS: {
    [key: string]: string;
} = Object.fromEntries(
    Object.entries(EMISSION_COLORS).map(([key, color]) => [
        key,
        generateIconAtlas(color),
    ]),
);

export const CROSSHAIR_ICONS_NO_CONTRAST: {
    [key: string]: string;
} = Object.fromEntries(
    Object.entries(EMISSION_COLORS).map(([key, color]) => [
        key,
        generateIconAtlas(color, true),
    ]),
);

export const EMISSION_LAYER_STYLES: {
    [key: string]: EmissionLayerStyle;
} = {
    thirdParty: {
        fillColor: [...EMISSION_COLORS.thirdParty, 150],
        lineColor: [...EMISSION_COLORS.thirdParty, 255],
        lineWidth: 2,
        highlightColor: [0, 0, 0, 30],
        groupFillColor: [...EMISSION_COLORS.thirdParty, 255],
        iconAtlas: CROSSHAIR_ICONS.thirdParty,
        textColor: [255, 255, 255, 255],
    },
    thirdPartyPublic: {
        fillColor: [...EMISSION_COLORS.thirdPartyPublic, 30],
        lineColor: [...EMISSION_COLORS.thirdPartyPublic, 0],
        lineWidth: 0,
        highlightColor: [0, 0, 0, 30],
        iconAtlas: CROSSHAIR_ICONS.thirdPartyPublic,
        textColor: [255, 255, 255, 255],
    },
    operatorProvided: {
        fillColor: [...EMISSION_COLORS.operatorProvided, 150],
        lineColor: [...EMISSION_COLORS.operatorProvided, 255],
        lineWidth: 2,
        highlightColor: [0, 0, 0, 30],
        groupFillColor: [...EMISSION_COLORS.operatorProvided, 255],
        iconAtlas: CROSSHAIR_ICONS.operatorProvided,
        textColor: [255, 255, 255, 255],
    },
    epa: {
        fillColor: [...EMISSION_COLORS.epa, 150],
        lineColor: [...EMISSION_COLORS.epa, 255],
        lineWidth: 2,
        highlightColor: [0, 0, 0, 30],
        groupFillColor: [...EMISSION_COLORS.epa, 255],
        iconAtlas: CROSSHAIR_ICONS.epa,
        textColor: [255, 255, 255, 255],
    },
    epaPublic: {
        fillColor: [...EMISSION_COLORS.epaPublic, 30],
        lineColor: [...EMISSION_COLORS.epaPublic, 0],
        lineWidth: 0,
        highlightColor: [0, 0, 0, 30],
        iconAtlas: CROSSHAIR_ICONS.epaPublic,
        textColor: [255, 255, 255, 255],
    },
};

export const EMISSION_BINS = [
    {
        label: "<100",
        lt: 100,
        size: 20,
    },
    {
        label: "100-10,000",
        gte: 100,
        lt: 10000,
        size: 35,
    },
    {
        label: ">10,000",
        gte: 10000,
        size: 50,
    },
];

export function getBinForRateOrConcentration(
    rate?: number,
    concentration?: number,
) {
    if (rate && (rate === 0 || isNaN(rate))) {
        return EMISSION_BINS[0];
    }

    if (rate > 0) {
        return (
            EMISSION_BINS.find((i) => {
                return (!i.lt || rate < i.lt) && (!i.gte || rate >= i.gte);
            }) || EMISSION_BINS[0]
        );
    }

    if (concentration > 10000) {
        return EMISSION_BINS[1];
    }

    return EMISSION_BINS[0];
}

export const MAP_INFRA_CLUSTERING_LIMIT = 9.5;
export const MAP_ZOOM_SHOW_DETAILS = 13;

interface LayerColorMapType {
    [key: string]: {
        fillColor: [number, number, number, number];
        lineColor: [number, number, number, number];
        highlightColor?: [number, number, number, number];
        lineWidth: number;
        pointRadius?: number;
    };
}

export const InfrastructureOnlyLayerColorMap: LayerColorMapType = {
    PIPELINE: {
        fillColor: [129, 50, 250, 0],
        lineColor: [0, 0, 0, 255],
        lineWidth: 5,
        pointRadius: 8,
    },
    SITE: {
        fillColor: [255, 0, 0, 255],
        lineColor: [0, 0, 0, 255],
        lineWidth: 3,
        highlightColor: [0, 0, 0, 20],
        pointRadius: 8,
    },
    EQUIPMENT_GROUP: {
        fillColor: [200, 200, 0, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        pointRadius: 6,
    },
    EQUIPMENT: {
        fillColor: [0, 200, 0, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        pointRadius: 4,
    },
    EQUIPMENT_COMPONENT: {
        fillColor: [0, 0, 255, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        pointRadius: 2,
    },
};

export const layerColorMap: LayerColorMapType = {
    SITE: {
        fillColor: [0, 0, 0, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 2,
        highlightColor: [0, 0, 0, 20],
    },
    PIPELINE: {
        fillColor: [0, 0, 0, 0],
        lineColor: [0, 0, 0, 255],
        lineWidth: 3,
    },
    EQUIPMENT: {
        fillColor: [20, 20, 20, 255],
        lineColor: [255, 255, 255, 255],
        lineWidth: 1,
    },
};
