/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CompanyUploadedDataPackage,
  CompanyUploadedDataPackageRequest,
  DataPackageArchiveUrl,
  DataPackageFileUploadRequest,
  DataPackagePresignedUploadUrl,
  DataPackagePresignedUploadUrlError,
  ListFilesInDataPackage,
  PaginatedCompanyUploadedDataPackageList,
} from '../models/index';
import {
    CompanyUploadedDataPackageFromJSON,
    CompanyUploadedDataPackageToJSON,
    CompanyUploadedDataPackageRequestFromJSON,
    CompanyUploadedDataPackageRequestToJSON,
    DataPackageArchiveUrlFromJSON,
    DataPackageArchiveUrlToJSON,
    DataPackageFileUploadRequestFromJSON,
    DataPackageFileUploadRequestToJSON,
    DataPackagePresignedUploadUrlFromJSON,
    DataPackagePresignedUploadUrlToJSON,
    DataPackagePresignedUploadUrlErrorFromJSON,
    DataPackagePresignedUploadUrlErrorToJSON,
    ListFilesInDataPackageFromJSON,
    ListFilesInDataPackageToJSON,
    PaginatedCompanyUploadedDataPackageListFromJSON,
    PaginatedCompanyUploadedDataPackageListToJSON,
} from '../models/index';

export interface DatauploadDataPackageCreateRequest {
    companyUploadedDataPackageRequest: CompanyUploadedDataPackageRequest;
}

export interface DatauploadDataPackageDownloadArchiveUrlRetrieveRequest {
    id: string;
}

export interface DatauploadDataPackageFilesInPackageRetrieveRequest {
    id: string;
}

export interface DatauploadDataPackageListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface DatauploadDataPackageMarkCompleteCreateRequest {
    id: string;
}

export interface DatauploadDataPackageRetrieveRequest {
    id: string;
}

export interface DatauploadDataPackageUploadUrlCreateRequest {
    id: string;
    dataPackageFileUploadRequest: DataPackageFileUploadRequest;
}

/**
 * 
 */
export class DatauploadApi extends runtime.BaseAPI {

    /**
     * A viewset to retrieve rasters information
     */
    async datauploadDataPackageCreateRaw(requestParameters: DatauploadDataPackageCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyUploadedDataPackage>> {
        if (requestParameters['companyUploadedDataPackageRequest'] == null) {
            throw new runtime.RequiredError(
                'companyUploadedDataPackageRequest',
                'Required parameter "companyUploadedDataPackageRequest" was null or undefined when calling datauploadDataPackageCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUploadedDataPackageRequestToJSON(requestParameters['companyUploadedDataPackageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUploadedDataPackageFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve rasters information
     */
    async datauploadDataPackageCreate(requestParameters: DatauploadDataPackageCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyUploadedDataPackage> {
        const response = await this.datauploadDataPackageCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generated a download URL for the archive file, if the user is an admin.
     */
    async datauploadDataPackageDownloadArchiveUrlRetrieveRaw(requestParameters: DatauploadDataPackageDownloadArchiveUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPackageArchiveUrl>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datauploadDataPackageDownloadArchiveUrlRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/{id}/download_archive_url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPackageArchiveUrlFromJSON(jsonValue));
    }

    /**
     * Generated a download URL for the archive file, if the user is an admin.
     */
    async datauploadDataPackageDownloadArchiveUrlRetrieve(requestParameters: DatauploadDataPackageDownloadArchiveUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPackageArchiveUrl> {
        const response = await this.datauploadDataPackageDownloadArchiveUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of files already uploaded to this data package.
     */
    async datauploadDataPackageFilesInPackageRetrieveRaw(requestParameters: DatauploadDataPackageFilesInPackageRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListFilesInDataPackage>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datauploadDataPackageFilesInPackageRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/{id}/files_in_package/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListFilesInDataPackageFromJSON(jsonValue));
    }

    /**
     * Returns a list of files already uploaded to this data package.
     */
    async datauploadDataPackageFilesInPackageRetrieve(requestParameters: DatauploadDataPackageFilesInPackageRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListFilesInDataPackage> {
        const response = await this.datauploadDataPackageFilesInPackageRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve rasters information
     */
    async datauploadDataPackageListRaw(requestParameters: DatauploadDataPackageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCompanyUploadedDataPackageList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCompanyUploadedDataPackageListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve rasters information
     */
    async datauploadDataPackageList(requestParameters: DatauploadDataPackageListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCompanyUploadedDataPackageList> {
        const response = await this.datauploadDataPackageListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a presigned upload URL so that the frontend can directly upload the file to the storage backend.
     */
    async datauploadDataPackageMarkCompleteCreateRaw(requestParameters: DatauploadDataPackageMarkCompleteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyUploadedDataPackage>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datauploadDataPackageMarkCompleteCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/{id}/mark_complete/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUploadedDataPackageFromJSON(jsonValue));
    }

    /**
     * Generates a presigned upload URL so that the frontend can directly upload the file to the storage backend.
     */
    async datauploadDataPackageMarkCompleteCreate(requestParameters: DatauploadDataPackageMarkCompleteCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyUploadedDataPackage> {
        const response = await this.datauploadDataPackageMarkCompleteCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve rasters information
     */
    async datauploadDataPackageRetrieveRaw(requestParameters: DatauploadDataPackageRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyUploadedDataPackage>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datauploadDataPackageRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUploadedDataPackageFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve rasters information
     */
    async datauploadDataPackageRetrieve(requestParameters: DatauploadDataPackageRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyUploadedDataPackage> {
        const response = await this.datauploadDataPackageRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a presigned upload URL so that the frontend can directly upload the file to the storage backend.
     */
    async datauploadDataPackageUploadUrlCreateRaw(requestParameters: DatauploadDataPackageUploadUrlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPackagePresignedUploadUrl>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling datauploadDataPackageUploadUrlCreate().'
            );
        }

        if (requestParameters['dataPackageFileUploadRequest'] == null) {
            throw new runtime.RequiredError(
                'dataPackageFileUploadRequest',
                'Required parameter "dataPackageFileUploadRequest" was null or undefined when calling datauploadDataPackageUploadUrlCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/dataupload/data-package/{id}/upload_url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataPackageFileUploadRequestToJSON(requestParameters['dataPackageFileUploadRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPackagePresignedUploadUrlFromJSON(jsonValue));
    }

    /**
     * Generates a presigned upload URL so that the frontend can directly upload the file to the storage backend.
     */
    async datauploadDataPackageUploadUrlCreate(requestParameters: DatauploadDataPackageUploadUrlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPackagePresignedUploadUrl> {
        const response = await this.datauploadDataPackageUploadUrlCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
