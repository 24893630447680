import Map from "react-map-gl/maplibre";
import DeckGL, { DeckGLRef } from "@deck.gl/react";
import { WebMercatorViewport } from "@deck.gl/core";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { basemaps } from "./basemaps";
import { useAtom } from "jotai";
import { useDebounce } from "@uidotdev/usehooks";
import * as turf from "@turf/turf";
import { mapStateFamily } from "./state";
import type { PickingInfo, LayersList } from "deck.gl";
import type {
    MjolnirGestureEvent,
    MjolnirPointerEvent,
} from "mjolnir.js/dist/esm";
import { MapScale } from "./elements/MapScale";
import { ZoomControlBase } from "./elements/ZoomAndLegend";
import { useMap } from "./hooks/mapState";
import { ControlContainer } from "./elements/common";
import { MeasureControlBase, useMapMeasuring } from "./elements/MeasureControl";
import { APIProvider, Map as GoogleMap } from "@vis.gl/react-google-maps";
import { GOOGLEMAPS_API_KEY } from "../../constants/globals";
import { BasemapControl } from "./elements/DataControls/BasemapControls";

interface MapBaseProps {
    mapId: string;
    layers: LayersList[];
    onLeftClick?: (mapData: {
        info: PickingInfo[];
        latitude: number;
        longitude: number;
        event: React.MouseEvent<HTMLDivElement>;
    }) => void;
    onRightClick?: (info: PickingInfo, event: MjolnirGestureEvent) => void;
    onHover?: (info: PickingInfo, event: MjolnirPointerEvent) => void;
    getTooltip?: (info: PickingInfo) => string | null;
    // Options
    showScaleControl?: boolean;
    showZoomControl?: boolean;
    showMeasureControl?: boolean;
    showBasemapControl?: boolean;
    cursor?: string;
    customPickingRange?: number;
    customPickingLimit?: number;
}

export const MapBase = (props: MapBaseProps) => {
    const deckRef = useRef<DeckGLRef>();
    const mapContainerRef = useRef<null | HTMLDivElement>(null);

    // Atom to hold map state here.
    const [mapState, setMapState] = useAtom(
        mapStateFamily({ mapId: props.mapId }),
    );

    // Const map
    const { zoomIn, zoomOut } = useMap(props.mapId);

    // Measurement
    const { measureLayers, isMeasuring, toggleMeasuring } = useMapMeasuring({
        enabled: true,
    });

    // Debounce viewstate (400 ms delay)
    const debouncedViewstate = useDebounce(mapState._viewState, 400);

    // Create a viewport for the map, based on debounced map view state
    // This causes a second rerender of the map, 400 ms after the user
    // stops moving the page.
    // Consider moving this to the `setter` of mapStateFamily.
    useEffect(() => {
        // Extract the map's viewport size for correct framing of points
        const mapWidth =
            mapContainerRef.current?.offsetWidth || window.innerWidth;
        const mapHeigth =
            mapContainerRef.current?.offsetHeight || window.innerHeight;

        // Viewport
        const viewport = new WebMercatorViewport({
            ...debouncedViewstate,
            width: mapWidth,
            height: mapHeigth,
        });

        // Calculate screen boundaries
        const upperRightScreenCoordinate = viewport.unproject([mapWidth, 0]);
        const lowerLeftScreenCoordinate = viewport.unproject([0, mapHeigth]);

        // Set areaOnMap and debouncedViewstate at the same time.
        setMapState((ms) => ({
            ...ms,
            debounced: {
                viewState: debouncedViewstate,
                areaOnScreen: turf.bboxPolygon(
                    lowerLeftScreenCoordinate.concat(
                        upperRightScreenCoordinate,
                    ),
                ),
            },
        }));
    }, [debouncedViewstate, mapContainerRef, setMapState]);

    // When map unmounts, clear debounced states
    useEffect(() => {
        return () => {
            setMapState((ms) => ({
                ...ms,
                debounced: {},
            }));
        };
    }, []);

    // Map picking mechanism, exposing only data picked.
    const { customPickingRange, customPickingLimit, onLeftClick } = props;
    const onClickHandler = useCallback(
        (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // Get mouse position relative to the containing div
            const containerRect = evt.currentTarget.getBoundingClientRect();
            const pickInfos = deckRef.current?.pickMultipleObjects({
                x: evt.clientX - containerRect.left,
                y: evt.clientY - containerRect.top,
                // Picking radius from central click (defaults to 0 pixels)
                radius: customPickingRange || 3,
                // Select at most N items at a time (defaults to 20 items)
                depth: customPickingLimit || 20,
            });

            // Create a viewport and compute latitude and longitude
            const viewport = new WebMercatorViewport({
                ...debouncedViewstate,
                width: mapContainerRef.current?.clientWidth,
                height: mapContainerRef.current?.clientHeight,
            });
            const coordinates = viewport.unproject([
                evt.clientX - containerRect.left,
                evt.clientY - containerRect.top,
            ]);

            // If any items were picked, deduplicate items
            if (pickInfos) {
                const seen = new Set();
                return {
                    latitude: coordinates[1],
                    longitude: coordinates[0],
                    info: pickInfos.filter((item) => {
                        const criteria =
                            item.object?.properties?.id ||
                            `${item.layer.id}-${item.index}`;
                        if (seen.has(criteria)) {
                            return false;
                        } else {
                            seen.add(criteria);
                            return true; // Unique, keep it
                        }
                    }),
                };
            }

            return {
                latitude: coordinates[1],
                longitude: coordinates[0],
                info: [],
            };
        },
        [
            deckRef,
            mapContainerRef,
            customPickingRange,
            customPickingLimit,
            debouncedViewstate,
            onLeftClick,
        ],
    );

    // Click detection mechanism, to avoid click events from
    // being triggered when the map is dragged.
    const handleMouseDown = useRef<number | null>(null);
    const handleMouseUp = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const clickDuration = Date.now() - (handleMouseDown.current || 0);
            handleMouseDown.current = null;
            // 200 ms holding => drag action
            if (clickDuration < 200 && props.onLeftClick) {
                props.onLeftClick({
                    ...onClickHandler(event),
                    event,
                });
            }
        },
        [onClickHandler],
    );

    const handleMouseDownEvent = useCallback(() => {
        handleMouseDown.current = Date.now();
    }, []);

    // Basemap handler
    const basemapData = useMemo(() => {
        if (mapState.basemap == null || mapState.basemap == "") {
            if (mapState._viewState.zoom > 12) {
                return basemaps.esri;
            }
            return basemaps.aershedCustom || basemaps.osm;
        }
        // Check if there's basemap data
        if (basemaps[mapState.basemap]) {
            return basemaps[mapState.basemap];
        }
        // Otherwise fall back to ESRI
        return basemaps.esri;
    }, [mapState]);

    return (
        <div
            ref={mapContainerRef}
            onContextMenu={(event) => {
                event.preventDefault();
            }}
            onMouseDown={handleMouseDownEvent}
            onMouseUp={handleMouseUp}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
            }}
            className="w-full h-full"
        >
            <APIProvider apiKey={GOOGLEMAPS_API_KEY}>
                <DeckGL
                    ref={deckRef}
                    viewState={mapState._viewState}
                    layers={[...props.layers, measureLayers]}
                    onViewStateChange={(newViewState) => {
                        setMapState((ms) => ({
                            ...ms,
                            _viewState: {
                                ...ms._viewState,
                                longitude: newViewState.viewState.longitude,
                                latitude: newViewState.viewState.latitude,
                                zoom: Math.max(
                                    newViewState.viewState.zoom,
                                    1.5,
                                ),
                            },
                        }));
                    }}
                    onClick={(info, event) => {
                        if (event.rightButton) {
                            props.onRightClick(info, event);
                        }
                    }}
                    onHover={props.onHover}
                    controller={true}
                    getCursor={({ isHovering, isDragging }) =>
                        isMeasuring
                            ? "crosshair"
                            : isDragging
                              ? "grabbing"
                              : isHovering
                                ? "pointer"
                                : props.cursor
                    }
                    getTooltip={props.getTooltip}
                >
                    {basemapData.googleMaps ? (
                        <GoogleMap disableDefaultUI={true} mapTypeId="hybrid" />
                    ) : (
                        <Map
                            id="mainMap"
                            mapStyle={basemapData.mapStyle as any}
                            attributionControl={false}
                        />
                    )}
                    <div className="absolute bottom-0 rounded-tr px-2 py-0.5 bg-white bg-opacity-60 text-xs">
                        {basemapData.attribution}
                    </div>
                    <div className="absolute bottom-2 right-2 flex gap-2 items-end">
                        {props.showScaleControl && (
                            <MapScale
                                latitude={mapState._viewState.latitude}
                                zoom={mapState._viewState.zoom}
                            />
                        )}
                        {(props.showZoomControl ||
                            props.showMeasureControl) && (
                            <ControlContainer className="flex-col gap-3 p-1 items-end">
                                {props.showZoomControl && (
                                    <ZoomControlBase
                                        zoomIn={zoomIn}
                                        zoomOut={zoomOut}
                                    />
                                )}
                                {props.showMeasureControl && (
                                    <MeasureControlBase
                                        measuring={isMeasuring}
                                        toggleMeasure={toggleMeasuring}
                                    />
                                )}
                                {props.showBasemapControl && (
                                    <BasemapControl
                                        basemap={mapState.basemap}
                                        toggleBasemap={(newBasemap) =>
                                            setMapState((s) => ({
                                                ...s,
                                                basemap: newBasemap,
                                            }))
                                        }
                                    />
                                )}
                            </ControlContainer>
                        )}
                    </div>
                </DeckGL>
            </APIProvider>
        </div>
    );
};
