/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConflictMatchingStatusEnum } from './ConflictMatchingStatusEnum';
import {
    ConflictMatchingStatusEnumFromJSON,
    ConflictMatchingStatusEnumFromJSONTyped,
    ConflictMatchingStatusEnumToJSON,
} from './ConflictMatchingStatusEnum';
import type { CsvDataSridEnum } from './CsvDataSridEnum';
import {
    CsvDataSridEnumFromJSON,
    CsvDataSridEnumFromJSONTyped,
    CsvDataSridEnumToJSON,
} from './CsvDataSridEnum';

/**
 * Infrastructure Import serializer.
 * @export
 * @interface PatchedInfrastructureImportRequest
 */
export interface PatchedInfrastructureImportRequest {
    /**
     * 
     * @type {CsvDataSridEnum}
     * @memberof PatchedInfrastructureImportRequest
     */
    csvDataSrid?: CsvDataSridEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedInfrastructureImportRequest
     */
    csvInputFilename?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedInfrastructureImportRequest
     */
    exactMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedInfrastructureImportRequest
     */
    nearMatchDistance?: number;
    /**
     * 
     * @type {ConflictMatchingStatusEnum}
     * @memberof PatchedInfrastructureImportRequest
     */
    conflictMatchingStatus?: ConflictMatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedInfrastructureImportRequest
     */
    similarityCheckerCentroidDistance?: number;
}

/**
 * Check if a given object implements the PatchedInfrastructureImportRequest interface.
 */
export function instanceOfPatchedInfrastructureImportRequest(value: object): boolean {
    return true;
}

export function PatchedInfrastructureImportRequestFromJSON(json: any): PatchedInfrastructureImportRequest {
    return PatchedInfrastructureImportRequestFromJSONTyped(json, false);
}

export function PatchedInfrastructureImportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedInfrastructureImportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'csvDataSrid': json['csv_data_srid'] == null ? undefined : CsvDataSridEnumFromJSON(json['csv_data_srid']),
        'csvInputFilename': json['csv_input_filename'] == null ? undefined : json['csv_input_filename'],
        'exactMatchDistance': json['exact_match_distance'] == null ? undefined : json['exact_match_distance'],
        'nearMatchDistance': json['near_match_distance'] == null ? undefined : json['near_match_distance'],
        'conflictMatchingStatus': json['conflict_matching_status'] == null ? undefined : ConflictMatchingStatusEnumFromJSON(json['conflict_matching_status']),
        'similarityCheckerCentroidDistance': json['similarity_checker_centroid_distance'] == null ? undefined : json['similarity_checker_centroid_distance'],
    };
}

export function PatchedInfrastructureImportRequestToJSON(value?: PatchedInfrastructureImportRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'csv_data_srid': CsvDataSridEnumToJSON(value['csvDataSrid']),
        'csv_input_filename': value['csvInputFilename'],
        'exact_match_distance': value['exactMatchDistance'],
        'near_match_distance': value['nearMatchDistance'],
        'conflict_matching_status': ConflictMatchingStatusEnumToJSON(value['conflictMatchingStatus']),
        'similarity_checker_centroid_distance': value['similarityCheckerCentroidDistance'],
    };
}

