import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { EventManagementModal } from "../Events/EventManagementModal";
import { EmissionApiDataTable } from "../Emissions/EmissionsApiDataTable";
import {
    EmissionStatusEnum,
    EmissioneventsListDataSourceEnum,
    EventStatusEnum,
} from "../../apiClient/generated";
import { DashboardTabsV2 } from "../../ui/Card";
import { EventsApiDataTable } from "../Events/EventsApiDataTable";

interface EmissionAndEventManagementProps {
    dataSource: EmissioneventsListDataSourceEnum[];
    emissionStatus?: EmissionStatusEnum[];
    eventStatus?: EventStatusEnum[];
    tabClassnames?: string;
    eventModalTitle?: string;
}

export const EmissionAndEventManagement = (
    props: EmissionAndEventManagementProps,
) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedEvent = useMemo(() => {
        return searchParams.get("selectedEvent");
    }, [searchParams]);

    const setSelectedEvent = (eventId?: string) => {
        setSearchParams((params) => {
            if (eventId) {
                params.set("selectedEvent", eventId);
            } else {
                params.delete("selectedEvent");
            }
            return params;
        });
    };

    return (
        <>
            <DashboardTabsV2
                tabs={[
                    {
                        name: "New",
                        child: (
                            <EmissionApiDataTable
                                dataSource={props.dataSource}
                                onClickRow={setSelectedEvent}
                                emissionStatus={["NEAR_MATCH", "MATCHED"]}
                                showStatus
                                showActions
                            />
                        ),
                        classname: props.tabClassnames,
                    },
                    {
                        name: "In progress",
                        child: (
                            <EventsApiDataTable
                                dataSource={props.dataSource}
                                onClickRow={setSelectedEvent}
                                emissionStatus={["ACCEPTED"]}
                                eventStatus={[
                                    "WORK_IN_PROGRESS",
                                    "WAITING_ACTION",
                                ]}
                            />
                        ),
                        classname: props.tabClassnames,
                    },
                    {
                        name: "Completed",
                        child: (
                            <EventsApiDataTable
                                dataSource={props.dataSource}
                                onClickRow={setSelectedEvent}
                                eventStatus={["COMPLETED"]}
                            />
                        ),
                        classname: props.tabClassnames,
                    },
                    {
                        name: "Rejected",
                        child: (
                            <EmissionApiDataTable
                                dataSource={props.dataSource}
                                onClickRow={setSelectedEvent}
                                emissionStatus={["REJECTED"]}
                                showActions
                            />
                        ),
                        classname: props.tabClassnames,
                    },
                ]}
            />

            {/* Modal window */}
            <EventManagementModal
                emissionId={selectedEvent}
                onClose={() => setSelectedEvent()}
                title={props.eventModalTitle}
            />
        </>
    );
};
