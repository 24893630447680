import { TrashIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { createColumnHelper } from "@tanstack/react-table";
import { useDebounce } from "@uidotdev/usehooks";
import { useState, useMemo } from "react";
import {
    InfrastructureImportItem,
    InfrastructureImport,
    AdminInfrastructureImportItem,
    AdminInfrastructureImport,
    InfraStatusEnum,
} from "../../../apiClient/generated";
import { useInfrastructureApiClient } from "../../../hooks";
import { CustomSwitch } from "../../../ui/CustomSwitch";
import { EditableAutocompleteCell } from "../../../ui/Table/CustomCells/EditableAutocomplete";
import {
    EditableTextCell,
    EditableInfraTypeCell,
    EditableAttributesCell,
    EditableDateCell,
    EditableEnumCell,
} from "../../../ui/Table/CustomCells/EditableCells";
import { EditableLocationCell } from "../../../ui/Table/CustomCells/EditableLocationCell";
import { LocationMatchingCell } from "../../../ui/Table/CustomCells/LocationMatchingCell";
import { DataTable } from "../../InfrastructureDataTable/DataTable";
import { AddItemModal } from "../TableView";
import { CrossOperatorConflictsCell } from "../../../ui/Table/CustomCells/CrossOperatorConflictsCell";

const columnHelper = createColumnHelper<AdminInfrastructureImportItem>();

interface TableViewProps {
    importData: AdminInfrastructureImport;
    refresh?: () => void;
}

export const AdminTableView = (props: TableViewProps) => {
    const apiClient = useInfrastructureApiClient();
    const [filterSearch, setFilterSearch] = useState("");
    const [showAddModal, setShowAddModal] = useState(false);
    const [filterCrossOperatorConflicts, setFilterCrossOperatorConflicts] =
        useState(false);
    const [filterNoLocation, setFilterNoLocation] = useState(false);
    const [filterLocationNeedsReview, setFilterLocationNeedsReview] =
        useState(false);
    const [filterMissingParent, setFilterMissingParent] = useState(false);
    const [filterEmptyId, setFilterEmptyId] = useState(false);
    const [filterNoInfraType, setFilterNoInfraType] = useState(false);
    const debouncedSearch = useDebounce(filterSearch, 400);

    const fetchFunction = async (fetchProps: any) => {
        return await apiClient.infrastructureAdminImportItemsList({
            ...fetchProps,
        });
    };

    const partialUpdate = async (
        id: string,
        value: Partial<AdminInfrastructureImportItem>,
    ) => {
        return apiClient.infrastructureAdminImportItemsPartialUpdate({
            id: parseInt(id),
            patchedAdminInfrastructureImportItemRequest: value,
        });
    };

    const deleteFunction = async (itemId: string) => {
        return await apiClient.infrastructureImportItemsDestroy({
            id: parseInt(itemId),
        });
    };

    const columns = useMemo(
        () => [
            columnHelper.accessor("placeholderId", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableTextCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { placeholderId: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Temp ID</span>,
            }),
            columnHelper.accessor("siteName", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableTextCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { siteName: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Site Name</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        location: row.location,
                        shape: row.shape,
                        pipelineShape: row.pipelineShape,
                        infraType: row.infraType,
                    };
                },
                {
                    id: "locationandshape",
                    header: () => <span>Location/Shape</span>,
                    cell: (info) => {
                        const geoData = info.getValue();
                        return (
                            <EditableLocationCell
                                initialLocation={geoData.location}
                                initialShape={geoData.shape}
                                initialPipelineShape={geoData.pipelineShape}
                                onChange={(
                                    newLocation,
                                    newShape,
                                    newPipelineShape,
                                ) =>
                                    info.table.options.meta?.updateData(
                                        info.row.index,
                                        info.row.original.id,
                                        {
                                            location: newLocation,
                                            shape: newShape,
                                            pipelineShape: newPipelineShape,
                                        },
                                    )
                                }
                            />
                        );
                    },
                },
            ),
            columnHelper.accessor("infraType", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableInfraTypeCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { infraType: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Category</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        equipmentType: row.equipmentType,
                        infraType: row.infraType,
                    };
                },
                {
                    id: "equipmentType",
                    cell: (info) => {
                        const value = info.getValue();
                        if (["SITE", "PIPELINE"].includes(value.infraType)) {
                            return <span className="text-slate-500">-</span>;
                        }
                        return (
                            <EditableTextCell
                                initialValue={value.equipmentType}
                                onChange={(newValue) =>
                                    info.table.options.meta?.updateData(
                                        info.row.index,
                                        info.row.original.id,
                                        { equipmentType: newValue },
                                    )
                                }
                            />
                        );
                    },
                    header: () => <span>Type</span>,
                },
            ),
            columnHelper.accessor("name", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableTextCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { siteName: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Name</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        infraType: row.infraType,
                        relatedImport: row.relatedImport,
                        id: row.id,
                        parent: row.parent,
                        parentPlaceholderId: row.parentPlaceholderId,
                    };
                },
                {
                    id: "parent",
                    cell: (info) => {
                        const value = info.getValue();
                        if (["PIPELINE"].includes(value.infraType)) {
                            return <span className="text-slate-500">-</span>;
                        }
                        const autocompleteParent = async (search: string) => {
                            const response =
                                await apiClient.infrastructureImportItemsList({
                                    parentFor: value.id,
                                    pageSize: 10,
                                    relatedImport: props.importData.id,
                                    search: search,
                                });
                            return response.results;
                        };
                        return (
                            <EditableAutocompleteCell<InfrastructureImportItem>
                                initialValue={{
                                    id: value.parent,
                                    placeholderId: value.parentPlaceholderId,
                                }}
                                getDisplayName={(item) =>
                                    item && item.placeholderId
                                }
                                getExtraData={(item) => {
                                    return [
                                        {
                                            name: "Site Name",
                                            value: item.siteName,
                                        },
                                        {
                                            name: "Category",
                                            value: item.infraType
                                                .replace("_", " ")
                                                .toLowerCase(),
                                        },
                                        {
                                            name: "Equipment type",
                                            value: item.equipmentType,
                                        },
                                    ];
                                }}
                                getKey={(item) => item && `item_${item.id}`}
                                queryFn={(search) => autocompleteParent(search)}
                                onChange={(newParent) => {
                                    return info.table.options.meta?.updateData(
                                        info.row.index,
                                        info.row.original.id,
                                        {
                                            parent: newParent
                                                ? newParent.id
                                                : null,
                                        },
                                    );
                                }}
                            />
                        );
                    },
                    header: () => <span>Parent</span>,
                },
            ),
            columnHelper.accessor("infraStatus", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableEnumCell<InfraStatusEnum>
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { infraStatus: newValue },
                                )
                            }
                            possibleValues={InfraStatusEnum}
                        />
                    );
                },
                header: () => <span>Status</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("dateOfInstallation", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableDateCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { dateOfInstallation: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Installation Date</span>,
                size: 200,
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor("extraData", {
                cell: (info) => {
                    const value = info.getValue();
                    return (
                        <EditableAttributesCell
                            initialValue={value}
                            onChange={(newValue) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { extraData: newValue },
                                )
                            }
                        />
                    );
                },
                header: () => <span>Extra attributes</span>,
            }),
            columnHelper.accessor(
                (row) => {
                    return {
                        id: row.id,
                        relatedImport: row.relatedImport,
                        crossOperatorConflictStatus:
                            row.crossOperatorConflictStatus,
                    };
                },
                {
                    id: "conflictStatus",
                    cell: (info) => {
                        const value = info.getValue();
                        return (
                            <CrossOperatorConflictsCell
                                itemId={value.id}
                                conflictStatus={
                                    value.crossOperatorConflictStatus
                                }
                                onChange={(crossOperatorConflictStatus) => {
                                    return info.table.options.meta?.updateData(
                                        info.row.index,
                                        value.id,
                                        {
                                            crossOperatorConflictStatus,
                                        },
                                    );
                                }}
                            />
                        );
                    },
                    header: () => <span>Conflicts</span>,
                },
            ),
            columnHelper.accessor(
                (row) => {
                    return {
                        id: row.id,
                        relatedImport: row.relatedImport,
                        matchStatus: row.matchStatus,
                        relatedInfrastructure: row.relatedInfrastructure,
                    };
                },
                {
                    id: "matchStatus",
                    cell: (info) => {
                        const value = info.getValue();
                        return (
                            <LocationMatchingCell
                                itemId={value.id}
                                matchStatus={value.matchStatus}
                                relatedInfrastructure={
                                    value.relatedInfrastructure
                                }
                                onChange={(matchStatus, selectedMatch) => {
                                    return info.table.options.meta?.updateData(
                                        info.row.index,
                                        value.id,
                                        {
                                            matchStatus,
                                            selectedMatch,
                                        },
                                    );
                                }}
                            />
                        );
                    },
                    header: () => <span>Location Match</span>,
                },
            ),
            columnHelper.display({
                id: "actions",
                header: () => "",
                cell: (props) => {
                    return (
                        <div className="flex items-center space-x-2">
                            <button
                                onClick={() => {
                                    if (
                                        confirm(
                                            "Are you sure you want to delete this item?",
                                        )
                                    ) {
                                        props.table.options.meta?.deleteRow(
                                            props.row.index,
                                            props.row.original.id,
                                        );
                                    }
                                }}
                                className="flex w-fit items-center p-2 rounded-md text-black hover:text-white hover:bg-slate-500"
                            >
                                <TrashIcon className="w-4 h-4" />
                            </button>
                        </div>
                    );
                },
            }),
        ],
        [props.importData, apiClient],
    );

    return (
        <>
            <div className="mx-4 mb-3 flex items-center text-sm justify-between">
                <div className="flex items-center mr-7">
                    <input
                        type="text"
                        className="rounded-lg py-1 px-2"
                        placeholder="Search by ID/Name"
                        value={filterSearch}
                        onChange={(e) => setFilterSearch(e.target.value)}
                    />
                    <MagnifyingGlassIcon className="w-5 h-5 -ml-7" />
                </div>
                <div className="flex flex-wrap ml-10">
                    <p className="mr-4">Only show items:</p>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterNoLocation}
                            onChange={() =>
                                setFilterNoLocation(!filterNoLocation)
                            }
                            size="sm"
                        />
                        <span className="ml-2">Missing location</span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterNoInfraType}
                            onChange={() =>
                                setFilterNoInfraType(!filterNoInfraType)
                            }
                            size="sm"
                        />
                        <span className="ml-2">
                            Missing infrastructure type
                        </span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterEmptyId}
                            onChange={() => setFilterEmptyId(!filterEmptyId)}
                            size="sm"
                        />
                        <span className="ml-2">With empty ID</span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterMissingParent}
                            onChange={() =>
                                setFilterMissingParent(!filterMissingParent)
                            }
                            size="sm"
                        />
                        <span className="ml-2">
                            Missing parent relationships
                        </span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterLocationNeedsReview}
                            onChange={() =>
                                setFilterLocationNeedsReview(
                                    !filterLocationNeedsReview,
                                )
                            }
                            size="sm"
                        />
                        <span className="ml-2">Location needs review</span>
                    </div>
                    <div className="mb-1 mr-4">
                        <CustomSwitch
                            checked={filterCrossOperatorConflicts}
                            onChange={() =>
                                setFilterCrossOperatorConflicts(
                                    !filterCrossOperatorConflicts,
                                )
                            }
                            size="sm"
                        />
                        <span className="ml-2">Cross operator conflicts</span>
                    </div>
                </div>
            </div>
            <hr />
            <DataTable
                dataName="infrastructure_import_items"
                columns={columns}
                extraFilters={{
                    relatedImport: props.importData.id,
                    emptyPlaceholderId: filterEmptyId ? true : undefined,
                    missingParent: filterMissingParent ? true : undefined,
                    noLocation: filterNoLocation ? true : undefined,
                    missingInfraType: filterNoInfraType ? true : undefined,
                    crossOperatorConflict: filterCrossOperatorConflicts
                        ? true
                        : undefined,
                    locationNeedsReview: filterLocationNeedsReview
                        ? true
                        : undefined,
                    search: debouncedSearch,
                }}
                fetchFunction={fetchFunction}
                defaultPageSize={20}
                partialUpdate={partialUpdate}
                deleteRow={deleteFunction}
                sortable={false}
                createRow={() => setShowAddModal(true)}
            />
            <AddItemModal
                onClose={() => setShowAddModal(false)}
                visible={showAddModal}
                onSave={() => setShowAddModal(false)}
                relatedImport={props.importData as InfrastructureImport}
            />
        </>
    );
};
