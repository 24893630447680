import { createColumnHelper } from "@tanstack/react-table";
import { useDebounce } from "@uidotdev/usehooks";
import { useAtom } from "jotai";
import { useState, useMemo } from "react";
import {
    EpaOperatorNotification,
    EpaSepNotificationsListStatusEnum,
    NotificationReasonEnum,
    PatchedEpaOperatorNotificationRequest,
} from "../../../apiClient/generated";
import { useEpaSepApiClient } from "../../../hooks";
import { DashboardTabsV2 } from "../../../ui/Card";
import { SearchInput } from "../../../ui/Inputs";
import { DataTableV3 } from "../../DataTable/DataTableV3";
import { tableStateFamily } from "../../DataTable/state";
import { DateTime } from "luxon";
import { PrimaryButton, SecondaryButton } from "../../../ui/Buttons";
import { EditableUserCell } from "../../../ui/Table/CustomCells/EditableUserCell";
import { useQueryClient } from "@tanstack/react-query";
import { computeNearestSite } from "./utils";
import { useResetAtom } from "jotai/utils";
import { faFilterCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columnHelper = createColumnHelper<EpaOperatorNotification>();

const useEpaSepColumns = (
    epaSepStatus: EpaSepNotificationsListStatusEnum[],
    onClickRow: (id: string) => void,
) => {
    return [
        columnHelper.display({
            id: "actions",
            header: "Actions",
            cell: (info) => (
                <PrimaryButton
                    variant="sm"
                    onClick={() =>
                        // Send the main emissionId instead of event ID to open modal.
                        onClickRow(info.row.original.id)
                    }
                >
                    {epaSepStatus.includes("SUBMITTED_TO_EPA")
                        ? "View"
                        : "Manage"}
                </PrimaryButton>
            ),
            enableColumnFilter: false,
        }),
        columnHelper.accessor("notification.notificationReportId", {
            id: "notificationReportId",
            header: "EPA Report ID",
            cell: (info) => {
                return info.getValue();
            },
            meta: {
                sortingKey: "notification__detected_rate",
            },
        }),
        columnHelper.accessor("notification.detectionDate", {
            id: "detectionDate",
            header: "Detected at (UTC)",
            cell: (info) => {
                const value = info.getValue();
                return DateTime.fromJSDate(value)
                    .setZone("utc")
                    .toLocaleString(DateTime.DATETIME_MED);
            },
            meta: {
                sortingKey: "notification__detection_date",
            },
        }),
        columnHelper.accessor(
            (row) => ({
                dueDate: row.notification.dueDate,
                status: row.status,
                reason: row.notificationReason,
            }),
            {
                id: "dueDate",
                header: "Due date",
                cell: (info) => {
                    const value = info.getValue();
                    if (!value.dueDate || value.reason != "EPA_NOTIFICATION") {
                        return "-";
                    }
                    const dueDate = DateTime.fromJSDate(value.dueDate).setZone(
                        "utc",
                    );
                    const today = DateTime.now().setZone("utc").startOf("day");
                    const daysRemaining = Math.floor(
                        dueDate.diff(today, "days").days,
                    );
                    // Return "Overdue" if 0 or negative days remaining, otherwise show the number
                    const daysRemainingStr =
                        daysRemaining < 0 ? (
                            <span className="text-red-500">Overdue</span>
                        ) : (
                            `${daysRemaining} day${daysRemaining > 1 ? "s" : ""} left`
                        );
                    return (
                        <div className="group flex items-center gap-1">
                            {dueDate.toLocaleString(DateTime.DATE_MED)}
                            {value.status !== "SUBMITTED_TO_EPA" && (
                                <>
                                    <span className="hidden group-hover/tableRow:block w-20 text-gray-400">
                                        ({daysRemainingStr})
                                    </span>
                                    <span className="block group-hover/tableRow:hidden w-20" />
                                </>
                            )}
                        </div>
                    );
                },
                meta: {
                    sortingKey: "notification__due_date",
                },
            },
        ),
        columnHelper.accessor(
            (row) => ({
                id: row.assignee,
                name: row.assigneeName,
                status: row.status,
            }),
            {
                id: "assignee",
                header: "Assignee",
                enableSorting: false,
                cell: (info) => {
                    const value = info.getValue();
                    if (value.status === "SUBMITTED_TO_EPA") {
                        return value.name;
                    }
                    return (
                        <EditableUserCell
                            assignee={value}
                            save={(userId) =>
                                info.table.options.meta?.updateData(
                                    info.row.index,
                                    info.row.original.id,
                                    { assignee: userId },
                                )
                            }
                        />
                    );
                },
            },
        ),
        columnHelper.accessor("notification.detectedRate", {
            id: "detectedRate",
            header: "Emission Rate",
            cell: (info) => {
                return `${(info.getValue() / 1000).toFixed(1)} kg/h`;
            },
            meta: {
                sortingKey: "notification__detected_rate",
            },
        }),
        columnHelper.accessor("epaIdentifiedSite", {
            id: "epaIdentifiedSite",
            header: "EPA Site",
            cell: (info) => {
                return info.getValue();
            },
            meta: {
                sortingKey: "epa_identified_site",
            },
        }),
        columnHelper.accessor((row) => row, {
            id: "nearestSite",
            header: "Nearest Site",
            cell: (info) => {
                const { site } = computeNearestSite(info.getValue());
                if (!site) {
                    return "-";
                }
                return site.siteName;
            },
            meta: {
                sortingKey: "epa_emission_record__infrastructure__site_name",
            },
        }),
        columnHelper.accessor((row) => row, {
            id: "distance",
            header: "Distance",
            enableSorting: false,
            cell: (info) => {
                const { site, distance } = computeNearestSite(info.getValue());
                if (!site) {
                    return "-";
                }
                return `${distance.toFixed(2)} m`;
            },
        }),
        columnHelper.accessor(
            (row) => ({
                provider: row.notification.providerName,
                notifier: row.notification.notifierName,
            }),
            {
                id: "providerAndNotifier",
                header: "Provider (Notifier)",
                enableSorting: false,
                cell: (info) => {
                    const value = info.getValue();
                    return `${value.provider} (${value.notifier})`;
                },
            },
        ),
    ];
};

interface ApiDataTable {
    epaSepStatus?: EpaSepNotificationsListStatusEnum[];
    notificationReason?: NotificationReasonEnum[];
    onClickRow: (id: string) => void;
}

export const EpaSepApiDataTable = (props: ApiDataTable) => {
    const apiClient = useEpaSepApiClient();
    const queryClient = useQueryClient();
    const [filterSearch, setFilterSearch] = useState("");
    const debouncedSearch = useDebounce(filterSearch, 600);

    // Table state
    const columns = useEpaSepColumns(props.epaSepStatus, props.onClickRow);
    const tableAtom = useMemo(
        () =>
            tableStateFamily({
                tableId: `epaSep-${props.epaSepStatus.join("")}`,
                initialState: {},
            }),
        [props.epaSepStatus],
    );
    useAtom(tableAtom);
    const resetTableState = useResetAtom(tableAtom);

    const fetchFunction = async (props: any) => {
        return await apiClient.epaSepNotificationsList(props);
    };

    const partialUpdate = async (
        id: string,
        value: PatchedEpaOperatorNotificationRequest,
    ) => {
        const response = await apiClient.epaSepNotificationsPartialUpdate({
            id: id,
            patchedEpaOperatorNotificationRequest: value,
        });
        queryClient.invalidateQueries({ queryKey: ["EpaSepStats"] });
        queryClient.invalidateQueries({ queryKey: ["EpaSepStatsBar"] });
        return response;
    };

    return (
        <div className="flex-1 flex flex-col whitespace-nowrap">
            {/* Search bar */}
            <div className="py-3 px-4 flex items-center text-sm justify-between border-b">
                <SearchInput
                    containerClassName="w-96"
                    placeholder="Search by EPA ID, site name or provider..."
                    value={filterSearch}
                    onChange={(newValue) => setFilterSearch(newValue)}
                />
                <SecondaryButton variant="sm" onClick={resetTableState}>
                    <FontAwesomeIcon
                        icon={faFilterCircleXmark}
                        className="w-4"
                    />
                    Clear filters
                </SecondaryButton>
            </div>

            {/* Data table */}
            <DataTableV3<EpaOperatorNotification>
                dataName={`epaSep-${props.epaSepStatus.join("")}`}
                columns={columns}
                extraFilters={{
                    search: debouncedSearch,
                    status: props.epaSepStatus,
                    notificationReason: props.notificationReason,
                }}
                fetchFunction={fetchFunction}
                sortable={true}
                filterable={false}
                // Have to ignore types in this case since the edit serializer
                // is different than the list serializer.
                partialUpdate={partialUpdate as unknown as any}
            />
        </div>
    );
};

interface EpaSepDashboardTablesProps {
    setEvent: (id: string) => void;
}

export const EpaSepDashboardTables = (props: EpaSepDashboardTablesProps) => {
    return (
        <DashboardTabsV2
            tabs={[
                {
                    name: "Not started",
                    child: (
                        <EpaSepApiDataTable
                            epaSepStatus={["CREATED", "OPERATOR_NOTIFIED"]}
                            notificationReason={["EPA_NOTIFICATION"]}
                            onClickRow={props.setEvent}
                        />
                    ),
                },
                {
                    name: "Investigation in progress",
                    child: (
                        <EpaSepApiDataTable
                            epaSepStatus={["INVESTIGATION_STARTED"]}
                            notificationReason={["EPA_NOTIFICATION"]}
                            onClickRow={props.setEvent}
                        />
                    ),
                },
                {
                    name: "Investigation Complete",
                    child: (
                        <EpaSepApiDataTable
                            epaSepStatus={["COMPLETE"]}
                            notificationReason={["EPA_NOTIFICATION"]}
                            onClickRow={props.setEvent}
                        />
                    ),
                },
                {
                    name: "Potential EPA Events",
                    classname: "justify-self-end",
                    end: true,
                    child: (
                        <EpaSepApiDataTable
                            epaSepStatus={[
                                "CREATED",
                                "INVESTIGATION_STARTED",
                                "COMPLETE",
                            ]}
                            notificationReason={["MATCHED_INFRASTRUCTURE"]}
                            onClickRow={props.setEvent}
                        />
                    ),
                },
            ]}
        />
    );
};
