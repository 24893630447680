/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChannelConfirmRequest,
  EmissionNotificationSettings,
  EmissionNotificationSettingsRequest,
  NotificationChannel,
  NotificationChannelRequest,
  NotificationGroup,
  NotificationGroupCreate,
  NotificationGroupCreateRequest,
  PaginatedEmissionNotificationSettingsList,
  PaginatedNotificationChannelList,
  PaginatedNotificationGroupList,
  PatchedEmissionNotificationSettingsRequest,
  PatchedNotificationChannelRequest,
  PatchedNotificationGroupCreateRequest,
} from '../models/index';
import {
    ChannelConfirmRequestFromJSON,
    ChannelConfirmRequestToJSON,
    EmissionNotificationSettingsFromJSON,
    EmissionNotificationSettingsToJSON,
    EmissionNotificationSettingsRequestFromJSON,
    EmissionNotificationSettingsRequestToJSON,
    NotificationChannelFromJSON,
    NotificationChannelToJSON,
    NotificationChannelRequestFromJSON,
    NotificationChannelRequestToJSON,
    NotificationGroupFromJSON,
    NotificationGroupToJSON,
    NotificationGroupCreateFromJSON,
    NotificationGroupCreateToJSON,
    NotificationGroupCreateRequestFromJSON,
    NotificationGroupCreateRequestToJSON,
    PaginatedEmissionNotificationSettingsListFromJSON,
    PaginatedEmissionNotificationSettingsListToJSON,
    PaginatedNotificationChannelListFromJSON,
    PaginatedNotificationChannelListToJSON,
    PaginatedNotificationGroupListFromJSON,
    PaginatedNotificationGroupListToJSON,
    PatchedEmissionNotificationSettingsRequestFromJSON,
    PatchedEmissionNotificationSettingsRequestToJSON,
    PatchedNotificationChannelRequestFromJSON,
    PatchedNotificationChannelRequestToJSON,
    PatchedNotificationGroupCreateRequestFromJSON,
    PatchedNotificationGroupCreateRequestToJSON,
} from '../models/index';

export interface NotificationsChannelsConfirmEmailCreateRequest {
    channelConfirmRequest: ChannelConfirmRequest;
}

export interface NotificationsChannelsCreateRequest {
    notificationChannelRequest: NotificationChannelRequest;
}

export interface NotificationsChannelsDestroyRequest {
    id: number;
}

export interface NotificationsChannelsListRequest {
    ordering?: string;
    owner?: number;
    page?: number;
    pageSize?: number;
}

export interface NotificationsChannelsPartialUpdateRequest {
    id: number;
    patchedNotificationChannelRequest?: PatchedNotificationChannelRequest;
}

export interface NotificationsChannelsResendEmailConfirmationCreateRequest {
    id: number;
}

export interface NotificationsChannelsRetrieveRequest {
    id: number;
}

export interface NotificationsChannelsUpdateRequest {
    id: number;
    notificationChannelRequest: NotificationChannelRequest;
}

export interface NotificationsEmissionsCreateRequest {
    emissionNotificationSettingsRequest?: EmissionNotificationSettingsRequest;
}

export interface NotificationsEmissionsDestroyRequest {
    id: number;
}

export interface NotificationsEmissionsListRequest {
    ordering?: string;
    owner?: number;
    page?: number;
    pageSize?: number;
}

export interface NotificationsEmissionsPartialUpdateRequest {
    id: number;
    patchedEmissionNotificationSettingsRequest?: PatchedEmissionNotificationSettingsRequest;
}

export interface NotificationsEmissionsRetrieveRequest {
    id: number;
}

export interface NotificationsEmissionsUpdateRequest {
    id: number;
    emissionNotificationSettingsRequest?: EmissionNotificationSettingsRequest;
}

export interface NotificationsGroupsCreateRequest {
    notificationGroupCreateRequest: NotificationGroupCreateRequest;
}

export interface NotificationsGroupsDestroyRequest {
    id: number;
}

export interface NotificationsGroupsListRequest {
    ordering?: string;
    owner?: number;
    page?: number;
    pageSize?: number;
}

export interface NotificationsGroupsPartialUpdateRequest {
    id: number;
    patchedNotificationGroupCreateRequest?: PatchedNotificationGroupCreateRequest;
}

export interface NotificationsGroupsRetrieveRequest {
    id: number;
}

export interface NotificationsGroupsUpdateRequest {
    id: number;
    notificationGroupCreateRequest: NotificationGroupCreateRequest;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Confirm email channel.  This is a public endpoint.
     */
    async notificationsChannelsConfirmEmailCreateRaw(requestParameters: NotificationsChannelsConfirmEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['channelConfirmRequest'] == null) {
            throw new runtime.RequiredError(
                'channelConfirmRequest',
                'Required parameter "channelConfirmRequest" was null or undefined when calling notificationsChannelsConfirmEmailCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/notifications/channels/confirm_email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChannelConfirmRequestToJSON(requestParameters['channelConfirmRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm email channel.  This is a public endpoint.
     */
    async notificationsChannelsConfirmEmailCreate(requestParameters: NotificationsChannelsConfirmEmailCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsChannelsConfirmEmailCreateRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsCreateRaw(requestParameters: NotificationsChannelsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationChannel>> {
        if (requestParameters['notificationChannelRequest'] == null) {
            throw new runtime.RequiredError(
                'notificationChannelRequest',
                'Required parameter "notificationChannelRequest" was null or undefined when calling notificationsChannelsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationChannelRequestToJSON(requestParameters['notificationChannelRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationChannelFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsCreate(requestParameters: NotificationsChannelsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationChannel> {
        const response = await this.notificationsChannelsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsDestroyRaw(requestParameters: NotificationsChannelsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsChannelsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsDestroy(requestParameters: NotificationsChannelsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsChannelsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsListRaw(requestParameters: NotificationsChannelsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotificationChannelList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['owner'] != null) {
            queryParameters['owner'] = requestParameters['owner'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotificationChannelListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsList(requestParameters: NotificationsChannelsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotificationChannelList> {
        const response = await this.notificationsChannelsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsPartialUpdateRaw(requestParameters: NotificationsChannelsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationChannel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsChannelsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotificationChannelRequestToJSON(requestParameters['patchedNotificationChannelRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationChannelFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsPartialUpdate(requestParameters: NotificationsChannelsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationChannel> {
        const response = await this.notificationsChannelsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the email confirmation.
     */
    async notificationsChannelsResendEmailConfirmationCreateRaw(requestParameters: NotificationsChannelsResendEmailConfirmationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsChannelsResendEmailConfirmationCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/{id}/resend_email_confirmation/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend the email confirmation.
     */
    async notificationsChannelsResendEmailConfirmationCreate(requestParameters: NotificationsChannelsResendEmailConfirmationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsChannelsResendEmailConfirmationCreateRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsRetrieveRaw(requestParameters: NotificationsChannelsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationChannel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsChannelsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationChannelFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsRetrieve(requestParameters: NotificationsChannelsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationChannel> {
        const response = await this.notificationsChannelsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsUpdateRaw(requestParameters: NotificationsChannelsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationChannel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsChannelsUpdate().'
            );
        }

        if (requestParameters['notificationChannelRequest'] == null) {
            throw new runtime.RequiredError(
                'notificationChannelRequest',
                'Required parameter "notificationChannelRequest" was null or undefined when calling notificationsChannelsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/channels/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationChannelRequestToJSON(requestParameters['notificationChannelRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationChannelFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification channels.
     */
    async notificationsChannelsUpdate(requestParameters: NotificationsChannelsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationChannel> {
        const response = await this.notificationsChannelsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsCreateRaw(requestParameters: NotificationsEmissionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionNotificationSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/emissions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmissionNotificationSettingsRequestToJSON(requestParameters['emissionNotificationSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionNotificationSettingsFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsCreate(requestParameters: NotificationsEmissionsCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionNotificationSettings> {
        const response = await this.notificationsEmissionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsDestroyRaw(requestParameters: NotificationsEmissionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsEmissionsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/emissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsDestroy(requestParameters: NotificationsEmissionsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsEmissionsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsListRaw(requestParameters: NotificationsEmissionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEmissionNotificationSettingsList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['owner'] != null) {
            queryParameters['owner'] = requestParameters['owner'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/emissions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEmissionNotificationSettingsListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsList(requestParameters: NotificationsEmissionsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEmissionNotificationSettingsList> {
        const response = await this.notificationsEmissionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsPartialUpdateRaw(requestParameters: NotificationsEmissionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionNotificationSettings>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsEmissionsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/emissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEmissionNotificationSettingsRequestToJSON(requestParameters['patchedEmissionNotificationSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionNotificationSettingsFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsPartialUpdate(requestParameters: NotificationsEmissionsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionNotificationSettings> {
        const response = await this.notificationsEmissionsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsRetrieveRaw(requestParameters: NotificationsEmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionNotificationSettings>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsEmissionsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/emissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionNotificationSettingsFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsRetrieve(requestParameters: NotificationsEmissionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionNotificationSettings> {
        const response = await this.notificationsEmissionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsUpdateRaw(requestParameters: NotificationsEmissionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmissionNotificationSettings>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsEmissionsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/emissions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EmissionNotificationSettingsRequestToJSON(requestParameters['emissionNotificationSettingsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmissionNotificationSettingsFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit emission notification settings
     */
    async notificationsEmissionsUpdate(requestParameters: NotificationsEmissionsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmissionNotificationSettings> {
        const response = await this.notificationsEmissionsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsCreateRaw(requestParameters: NotificationsGroupsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationGroupCreate>> {
        if (requestParameters['notificationGroupCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'notificationGroupCreateRequest',
                'Required parameter "notificationGroupCreateRequest" was null or undefined when calling notificationsGroupsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/groups/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationGroupCreateRequestToJSON(requestParameters['notificationGroupCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationGroupCreateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsCreate(requestParameters: NotificationsGroupsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationGroupCreate> {
        const response = await this.notificationsGroupsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsDestroyRaw(requestParameters: NotificationsGroupsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsGroupsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsDestroy(requestParameters: NotificationsGroupsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsGroupsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsListRaw(requestParameters: NotificationsGroupsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotificationGroupList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['owner'] != null) {
            queryParameters['owner'] = requestParameters['owner'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotificationGroupListFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsList(requestParameters: NotificationsGroupsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotificationGroupList> {
        const response = await this.notificationsGroupsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsPartialUpdateRaw(requestParameters: NotificationsGroupsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationGroupCreate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsGroupsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotificationGroupCreateRequestToJSON(requestParameters['patchedNotificationGroupCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationGroupCreateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsPartialUpdate(requestParameters: NotificationsGroupsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationGroupCreate> {
        const response = await this.notificationsGroupsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsRetrieveRaw(requestParameters: NotificationsGroupsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationGroup>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsGroupsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationGroupFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsRetrieve(requestParameters: NotificationsGroupsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationGroup> {
        const response = await this.notificationsGroupsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsUpdateRaw(requestParameters: NotificationsGroupsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationGroupCreate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notificationsGroupsUpdate().'
            );
        }

        if (requestParameters['notificationGroupCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'notificationGroupCreateRequest',
                'Required parameter "notificationGroupCreateRequest" was null or undefined when calling notificationsGroupsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/notifications/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationGroupCreateRequestToJSON(requestParameters['notificationGroupCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationGroupCreateFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve and edit notification group.
     */
    async notificationsGroupsUpdate(requestParameters: NotificationsGroupsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationGroupCreate> {
        const response = await this.notificationsGroupsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
