/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EpaNotificationLocation } from './EpaNotificationLocation';
import {
    EpaNotificationLocationFromJSON,
    EpaNotificationLocationFromJSONTyped,
    EpaNotificationLocationToJSON,
} from './EpaNotificationLocation';
import type { PlumeImageRequest } from './PlumeImageRequest';
import {
    PlumeImageRequestFromJSON,
    PlumeImageRequestFromJSONTyped,
    PlumeImageRequestToJSON,
} from './PlumeImageRequest';

/**
 * Serializer to represent `DataPoint` information.
 * @export
 * @interface SimpleDataPointRequest
 */
export interface SimpleDataPointRequest {
    /**
     * 
     * @type {string}
     * @memberof SimpleDataPointRequest
     */
    dataBatch: string;
    /**
     * 
     * @type {PlumeImageRequest}
     * @memberof SimpleDataPointRequest
     */
    plumeImage: PlumeImageRequest;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDataPointRequest
     */
    emissionDetected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPointRequest
     */
    detectedRate?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPointRequest
     */
    detectedRateUncertainty?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPointRequest
     */
    detectionLimit: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPointRequest
     */
    concentration?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDataPointRequest
     */
    concentrationUncertainty?: number;
    /**
     * 
     * @type {EpaNotificationLocation}
     * @memberof SimpleDataPointRequest
     */
    location: EpaNotificationLocation;
    /**
     * 
     * @type {any}
     * @memberof SimpleDataPointRequest
     */
    data?: any;
    /**
     * 
     * @type {Date}
     * @memberof SimpleDataPointRequest
     */
    detectionTimestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof SimpleDataPointRequest
     */
    secondaryDataSource?: string;
}

/**
 * Check if a given object implements the SimpleDataPointRequest interface.
 */
export function instanceOfSimpleDataPointRequest(value: object): boolean {
    if (!('dataBatch' in value)) return false;
    if (!('plumeImage' in value)) return false;
    if (!('detectionLimit' in value)) return false;
    if (!('location' in value)) return false;
    if (!('detectionTimestamp' in value)) return false;
    return true;
}

export function SimpleDataPointRequestFromJSON(json: any): SimpleDataPointRequest {
    return SimpleDataPointRequestFromJSONTyped(json, false);
}

export function SimpleDataPointRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleDataPointRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'dataBatch': json['data_batch'],
        'plumeImage': PlumeImageRequestFromJSON(json['plume_image']),
        'emissionDetected': json['emission_detected'] == null ? undefined : json['emission_detected'],
        'detectedRate': json['detected_rate'] == null ? undefined : json['detected_rate'],
        'detectedRateUncertainty': json['detected_rate_uncertainty'] == null ? undefined : json['detected_rate_uncertainty'],
        'detectionLimit': json['detection_limit'],
        'concentration': json['concentration'] == null ? undefined : json['concentration'],
        'concentrationUncertainty': json['concentration_uncertainty'] == null ? undefined : json['concentration_uncertainty'],
        'location': EpaNotificationLocationFromJSON(json['location']),
        'data': json['data'] == null ? undefined : json['data'],
        'detectionTimestamp': (new Date(json['detection_timestamp'])),
        'secondaryDataSource': json['secondary_data_source'] == null ? undefined : json['secondary_data_source'],
    };
}

export function SimpleDataPointRequestToJSON(value?: SimpleDataPointRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data_batch': value['dataBatch'],
        'plume_image': PlumeImageRequestToJSON(value['plumeImage']),
        'emission_detected': value['emissionDetected'],
        'detected_rate': value['detectedRate'],
        'detected_rate_uncertainty': value['detectedRateUncertainty'],
        'detection_limit': value['detectionLimit'],
        'concentration': value['concentration'],
        'concentration_uncertainty': value['concentrationUncertainty'],
        'location': EpaNotificationLocationToJSON(value['location']),
        'data': value['data'],
        'detection_timestamp': ((value['detectionTimestamp']).toISOString()),
        'secondary_data_source': value['secondaryDataSource'],
    };
}

