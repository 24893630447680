export const configurableColumns = [
    { id: "name", name: "Name" },
    { id: "siteName", name: "Site Name" },
    { id: "infraStatus", name: "Status" },
    { id: "dateOfInstallation", name: "Installation Date" },
    { id: "owner", name: "Owner" },
    { id: "infraType", name: "Infrastructure" },
    { id: "equipmentType", name: "Equipment Type" },
    { id: "location", name: "Location" },
    { id: "parent", name: "Parent" },
];
