/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpaNotificationLocation
 */
export interface EpaNotificationLocation {
    /**
     * 
     * @type {string}
     * @memberof EpaNotificationLocation
     */
    type?: EpaNotificationLocationTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof EpaNotificationLocation
     */
    coordinates?: Array<number>;
}


/**
 * @export
 */
export const EpaNotificationLocationTypeEnum = {
    Point: 'Point'
} as const;
export type EpaNotificationLocationTypeEnum = typeof EpaNotificationLocationTypeEnum[keyof typeof EpaNotificationLocationTypeEnum];


/**
 * Check if a given object implements the EpaNotificationLocation interface.
 */
export function instanceOfEpaNotificationLocation(value: object): boolean {
    return true;
}

export function EpaNotificationLocationFromJSON(json: any): EpaNotificationLocation {
    return EpaNotificationLocationFromJSONTyped(json, false);
}

export function EpaNotificationLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaNotificationLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'coordinates': json['coordinates'] == null ? undefined : json['coordinates'],
    };
}

export function EpaNotificationLocationToJSON(value?: EpaNotificationLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'coordinates': value['coordinates'],
    };
}

