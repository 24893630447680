/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ConflictMatchingStatusEnum } from './ConflictMatchingStatusEnum';
import {
    ConflictMatchingStatusEnumFromJSON,
    ConflictMatchingStatusEnumFromJSONTyped,
    ConflictMatchingStatusEnumToJSON,
} from './ConflictMatchingStatusEnum';
import type { CsvDataSridEnum } from './CsvDataSridEnum';
import {
    CsvDataSridEnumFromJSON,
    CsvDataSridEnumFromJSONTyped,
    CsvDataSridEnumToJSON,
} from './CsvDataSridEnum';
import type { DeduplicationStatusEnum } from './DeduplicationStatusEnum';
import {
    DeduplicationStatusEnumFromJSON,
    DeduplicationStatusEnumFromJSONTyped,
    DeduplicationStatusEnumToJSON,
} from './DeduplicationStatusEnum';
import type { ImportStatusEnum } from './ImportStatusEnum';
import {
    ImportStatusEnumFromJSON,
    ImportStatusEnumFromJSONTyped,
    ImportStatusEnumToJSON,
} from './ImportStatusEnum';
import type { MatchingStatusEnum } from './MatchingStatusEnum';
import {
    MatchingStatusEnumFromJSON,
    MatchingStatusEnumFromJSONTyped,
    MatchingStatusEnumToJSON,
} from './MatchingStatusEnum';

/**
 * Infrastructure Import serializer.
 * @export
 * @interface InfrastructureImport
 */
export interface InfrastructureImport {
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImport
     */
    readonly id: string;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImport
     */
    readonly owner: number;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImport
     */
    readonly ownerName: string;
    /**
     * 
     * @type {ImportStatusEnum}
     * @memberof InfrastructureImport
     */
    readonly importStatus: ImportStatusEnum;
    /**
     * 
     * @type {CsvDataSridEnum}
     * @memberof InfrastructureImport
     */
    csvDataSrid?: CsvDataSridEnum;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImport
     */
    csvInputFilename: string;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImport
     */
    readonly createdBy: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImport
     */
    readonly createdByName: string;
    /**
     * 
     * @type {Date}
     * @memberof InfrastructureImport
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof InfrastructureImport
     */
    readonly updatedAt: Date | null;
    /**
     * Computes count of children items.
     * @type {number}
     * @memberof InfrastructureImport
     */
    readonly itemCount: number;
    /**
     * 
     * @type {DeduplicationStatusEnum}
     * @memberof InfrastructureImport
     */
    readonly deduplicationStatus: DeduplicationStatusEnum;
    /**
     * 
     * @type {MatchingStatusEnum}
     * @memberof InfrastructureImport
     */
    readonly matchingStatus: MatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImport
     */
    exactMatchDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImport
     */
    nearMatchDistance?: number;
    /**
     * 
     * @type {ConflictMatchingStatusEnum}
     * @memberof InfrastructureImport
     */
    conflictMatchingStatus?: ConflictMatchingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImport
     */
    similarityCheckerCentroidDistance?: number;
    /**
     * 
     * @type {any}
     * @memberof InfrastructureImport
     */
    readonly errors: any | null;
}

/**
 * Check if a given object implements the InfrastructureImport interface.
 */
export function instanceOfInfrastructureImport(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('ownerName' in value)) return false;
    if (!('importStatus' in value)) return false;
    if (!('csvInputFilename' in value)) return false;
    if (!('createdBy' in value)) return false;
    if (!('createdByName' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('itemCount' in value)) return false;
    if (!('deduplicationStatus' in value)) return false;
    if (!('matchingStatus' in value)) return false;
    if (!('errors' in value)) return false;
    return true;
}

export function InfrastructureImportFromJSON(json: any): InfrastructureImport {
    return InfrastructureImportFromJSONTyped(json, false);
}

export function InfrastructureImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureImport {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'owner': json['owner'],
        'ownerName': json['owner_name'],
        'importStatus': ImportStatusEnumFromJSON(json['import_status']),
        'csvDataSrid': json['csv_data_srid'] == null ? undefined : CsvDataSridEnumFromJSON(json['csv_data_srid']),
        'csvInputFilename': json['csv_input_filename'],
        'createdBy': json['created_by'],
        'createdByName': json['created_by_name'],
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
        'itemCount': json['item_count'],
        'deduplicationStatus': DeduplicationStatusEnumFromJSON(json['deduplication_status']),
        'matchingStatus': MatchingStatusEnumFromJSON(json['matching_status']),
        'exactMatchDistance': json['exact_match_distance'] == null ? undefined : json['exact_match_distance'],
        'nearMatchDistance': json['near_match_distance'] == null ? undefined : json['near_match_distance'],
        'conflictMatchingStatus': json['conflict_matching_status'] == null ? undefined : ConflictMatchingStatusEnumFromJSON(json['conflict_matching_status']),
        'similarityCheckerCentroidDistance': json['similarity_checker_centroid_distance'] == null ? undefined : json['similarity_checker_centroid_distance'],
        'errors': json['errors'],
    };
}

export function InfrastructureImportToJSON(value?: Omit<InfrastructureImport, 'id'|'owner'|'owner_name'|'import_status'|'created_by'|'created_by_name'|'created_at'|'updated_at'|'item_count'|'deduplication_status'|'matching_status'|'errors'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'csv_data_srid': CsvDataSridEnumToJSON(value['csvDataSrid']),
        'csv_input_filename': value['csvInputFilename'],
        'exact_match_distance': value['exactMatchDistance'],
        'near_match_distance': value['nearMatchDistance'],
        'conflict_matching_status': ConflictMatchingStatusEnumToJSON(value['conflictMatchingStatus']),
        'similarity_checker_centroid_distance': value['similarityCheckerCentroidDistance'],
    };
}

