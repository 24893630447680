import { useState } from "react";
import { SecondaryButton } from "../../ui/Buttons";
import { Modal } from "../../ui/Modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableColumns } from "@fortawesome/sharp-light-svg-icons";
import { CustomSwitch } from "../../ui/CustomSwitch";

type ColumnVisibilityDef = {
    id: string;
    name: string;
}[];

interface TableSettingsModalProps {
    columnVisibility: {
        [k: string]: boolean;
    };
    setColumnVisibility: (newStates: { [k: string]: boolean }) => void;
    configurableColumns: ColumnVisibilityDef;
}

export const TableSettingsModal = (props: TableSettingsModalProps) => {
    const [showTableSettings, setShowTableSettings] = useState(false);

    return (
        <>
            <SecondaryButton
                onClick={() => setShowTableSettings(true)}
                variant="sm"
            >
                <FontAwesomeIcon icon={faTableColumns} className="w-4" />
                Configure table
            </SecondaryButton>

            {/* Table settings modal */}
            <Modal
                visible={showTableSettings}
                size="sm"
                onClose={() => setShowTableSettings(false)}
            >
                <h1 className="text-base font-bold mt-1">Table settings</h1>
                <p className="text-sm text-neutral-500">
                    Toggle column visibility for the infrastructure table.
                </p>

                <div className="text-sm mt-6 flex flex-col divide-y max-h-[300px] overflow-auto">
                    {props.configurableColumns.map((c, index) => (
                        <div
                            className="flex items-center gap-4 py-3"
                            key={index}
                        >
                            <CustomSwitch
                                checked={
                                    !(c.id in props.columnVisibility) ||
                                    props.columnVisibility[c.id]
                                }
                                onChange={(v) => {
                                    const newValues = {
                                        ...props.columnVisibility,
                                    };
                                    newValues[c.id] = v;
                                    props.setColumnVisibility(newValues);
                                }}
                                size="xs"
                            />
                            {c.name}
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    );
};
