import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import {
    AccountsApi,
    AdminApi,
    AuthApi,
    DatauploadApi,
    EmissionRecordsApi,
    InfrastructureApi,
    MapApi,
    EmissionEventsApi,
    NondetectsApi,
    RootCausesApi,
    DatadownloadsApi,
    DataProvidersApi,
    DataImportApi,
    NotificationsApi,
    DataPointsApi,
    GeodataApi,
    EpaSepApi,
} from "./apiClient";
import { useEffect, useMemo } from "react";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAccountsApiClient = () => {
    return useMemo(() => AccountsApi(), []);
};

export const useAuthApiClient = () => {
    return useMemo(() => AuthApi(), []);
};

export const useDatauploadApiClient = () => {
    return useMemo(() => DatauploadApi(), []);
};

export const useDatadownloadsApiClient = () => {
    return useMemo(() => DatadownloadsApi(), []);
};

export const useEmissionRecordsApiClient = () => {
    return useMemo(() => EmissionRecordsApi(), []);
};

export const useInfrastructureApiClient = () => {
    return useMemo(() => InfrastructureApi(), []);
};

export const useNondetectsApiClient = () => {
    return useMemo(() => NondetectsApi(), []);
};

export const useDataImportApiClient = () => {
    return useMemo(() => DataImportApi(), []);
};

export const useMapApiClient = () => {
    return useMemo(() => MapApi(), []);
};

export const useEmissionEventsApiClient = () => {
    return useMemo(() => EmissionEventsApi(), []);
};

export const useEpaSepApiClient = () => {
    return useMemo(() => EpaSepApi(), []);
};

export const useRootCausesApiClient = () => {
    return useMemo(() => RootCausesApi(), []);
};

export const useDataProvidersApi = () => {
    return useMemo(() => DataProvidersApi(), []);
};

export const useNotificationsApi = () => {
    return useMemo(() => NotificationsApi(), []);
};

export const useDataPointsApi = () => {
    return useMemo(() => DataPointsApi(), []);
};

export const useAdminApiClient = () => {
    return useMemo(() => AdminApi(), []);
};

export const useGeoDataApi = () => {
    return useMemo(() => GeodataApi(), []);
};

// Custom hook to deselect things
// TODO: replace with the `use-hooks` version.
export const useOnClickAway = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (evt) => {
            if (ref.current && !ref.current.contains(evt.target)) {
                callback();
            }
        };
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    });
};
