import {
    ArrowTopRightOnSquareIcon,
    CheckCircleIcon,
    XCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useAuthApiClient } from "../../hooks";
import { BACKEND_URL } from "../../constants/globals";
import {
    ArrowPathIcon,
    ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";

export const AdminDjangoAdminPage = () => {
    const authApiClient = useAuthApiClient();
    const [loading, setLoading] = useState(false);
    const [adminSessionCookie, setAdminSessionCookie] = useState<string | null>(
        null,
    );

    useEffect(() => {
        const fetchSessionId = async () => {
            setLoading(true);
            try {
                const status = await authApiClient.authAdminAuthRetrieve({
                    credentials: "include",
                });
                if (status.success) {
                    setAdminSessionCookie(status.sessionid);
                } else {
                    setAdminSessionCookie("");
                }
            } catch {
                setAdminSessionCookie("");
            } finally {
                setLoading(false);
            }
        };
        if (adminSessionCookie === null) {
            fetchSessionId();
        }
    }, [authApiClient, adminSessionCookie]);

    const startAdminSession = async () => {
        setLoading(true);
        try {
            const response = await authApiClient.authAdminAuthCreate({
                credentials: "include",
            });

            // Redirect to Django Admin
            setAdminSessionCookie(response.sessionid);
        } catch {
            alert(
                "Error starting your Django Admin session. Ensure you are logged in with the right user and try again.",
            );
        }
        setLoading(false);
    };

    return (
        <div className="w-full h-full">
            <div className="flex justify-between py-4 px-10 items-center">
                <h1 className="text-xl">Django Administration Access</h1>
            </div>
            <hr />

            <div className="w-full px-10 py-3">
                <p className="mt-2">
                    You can use this page to access Django Admin to manage users
                    and change platform data.
                </p>
                <div className="mt-2">
                    Notes:
                    <ul className="mt-1 list-disc list-inside">
                        <li>
                            Django Admin sessions are linked to Aershed
                            sessions.
                        </li>
                        <li>
                            Logging out of Aershed will end your Django Admin
                            session.
                        </li>
                    </ul>
                </div>

                <hr className="my-2" />

                <p className="mt-2 flex">
                    Session state:
                    {adminSessionCookie ? (
                        <span className="font-bold ml-2 flex items-center">
                            Active
                            <CheckCircleIcon className="ml-1 w-4 h-4 text-green-500" />
                        </span>
                    ) : (
                        <span className="font-bold ml-2 flex items-center">
                            Inactive
                            <XCircleIcon className="ml-1 w-4 h-4 text-red-500" />
                        </span>
                    )}
                </p>

                {adminSessionCookie ? (
                    <div className="flex">
                        <button
                            onClick={() =>
                                window
                                    .open(`${BACKEND_URL}/admin`, "_blank")
                                    .focus()
                            }
                            className="px-4 py-2 rounded-lg flex items-center mt-4 bg-slate-300 hover:bg-slate-700 hover:text-white"
                        >
                            Open Django Admin
                            <ArrowTopRightOnSquareIcon className="ml-2 w-5 h-5" />
                        </button>
                    </div>
                ) : !loading ? (
                    <button
                        onClick={startAdminSession}
                        className="px-4 py-2 rounded-lg flex items-center mt-4 bg-slate-300 hover:bg-slate-700 hover:text-white"
                    >
                        Start admin session
                        <ArrowRightOnRectangleIcon className="ml-2 w-5 h-5" />
                    </button>
                ) : (
                    <p className="mt-4 flex items-center">
                        Loading...
                        <ArrowPathIcon className="ml-2 w-5 h-5 animate-spin" />
                    </p>
                )}
            </div>
        </div>
    );
};
