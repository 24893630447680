/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EpaNotificationLocation } from './EpaNotificationLocation';
import {
    EpaNotificationLocationFromJSON,
    EpaNotificationLocationFromJSONTyped,
    EpaNotificationLocationToJSON,
} from './EpaNotificationLocation';

/**
 * Serializer for the EpaNotification model.
 * @export
 * @interface EpaNotification
 */
export interface EpaNotification {
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    notificationReportId: string;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    detectionDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    notificationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    dueDate?: Date;
    /**
     * 
     * @type {EpaNotificationLocation}
     * @memberof EpaNotification
     */
    location: EpaNotificationLocation;
    /**
     * Detected rate in g/hr
     * @type {number}
     * @memberof EpaNotification
     */
    detectedRate: number;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    notifierId?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    notifierName?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof EpaNotification
     */
    detectionTechnology?: string;
    /**
     * Wind speed in m/s
     * @type {number}
     * @memberof EpaNotification
     */
    windSpeed?: number;
    /**
     * Wind direction in degrees
     * @type {number}
     * @memberof EpaNotification
     */
    windDirection?: number;
    /**
     * 
     * @type {number}
     * @memberof EpaNotification
     */
    temperature?: number;
    /**
     * Humidity in g/kg
     * @type {number}
     * @memberof EpaNotification
     */
    humidity?: number;
    /**
     * 
     * @type {any}
     * @memberof EpaNotification
     */
    metadata?: any;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EpaNotification
     */
    readonly updatedAt: Date | null;
}

/**
 * Check if a given object implements the EpaNotification interface.
 */
export function instanceOfEpaNotification(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('notificationReportId' in value)) return false;
    if (!('detectionDate' in value)) return false;
    if (!('location' in value)) return false;
    if (!('detectedRate' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    return true;
}

export function EpaNotificationFromJSON(json: any): EpaNotification {
    return EpaNotificationFromJSONTyped(json, false);
}

export function EpaNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EpaNotification {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'notificationReportId': json['notification_report_id'],
        'detectionDate': (new Date(json['detection_date'])),
        'notificationDate': json['notification_date'] == null ? undefined : (new Date(json['notification_date'])),
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'location': EpaNotificationLocationFromJSON(json['location']),
        'detectedRate': json['detected_rate'],
        'notifierId': json['notifier_id'] == null ? undefined : json['notifier_id'],
        'notifierName': json['notifier_name'] == null ? undefined : json['notifier_name'],
        'providerName': json['provider_name'] == null ? undefined : json['provider_name'],
        'detectionTechnology': json['detection_technology'] == null ? undefined : json['detection_technology'],
        'windSpeed': json['wind_speed'] == null ? undefined : json['wind_speed'],
        'windDirection': json['wind_direction'] == null ? undefined : json['wind_direction'],
        'temperature': json['temperature'] == null ? undefined : json['temperature'],
        'humidity': json['humidity'] == null ? undefined : json['humidity'],
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
    };
}

export function EpaNotificationToJSON(value?: Omit<EpaNotification, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notification_report_id': value['notificationReportId'],
        'detection_date': ((value['detectionDate']).toISOString()),
        'notification_date': value['notificationDate'] == null ? undefined : ((value['notificationDate'] as any).toISOString()),
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString()),
        'location': EpaNotificationLocationToJSON(value['location']),
        'detected_rate': value['detectedRate'],
        'notifier_id': value['notifierId'],
        'notifier_name': value['notifierName'],
        'provider_name': value['providerName'],
        'detection_technology': value['detectionTechnology'],
        'wind_speed': value['windSpeed'],
        'wind_direction': value['windDirection'],
        'temperature': value['temperature'],
        'humidity': value['humidity'],
        'metadata': value['metadata'],
    };
}

