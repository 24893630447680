export const ROUTES = {
    LOGIN: "/login",
    IP_NOT_WHITELISTED: "/ip-not-whitelisted",
    SSO_COMPLETE: "/sso-complete",
    PASSWORD_RESET: "/password-reset",
    NOTIFICATION_CHANNEL_VALIDATION: "/validate-channel",
    LOGOUT: "/logout",
    ROOT: "/",
    MAP: "/map",
    EMISSIONS: "/emissions",
    EMISSION_RECORDS: "/emission-records",
    INFRASTRUCURE: "/infrastructure",
    INFRASTRUCURE_DETAIL: "/infrastructure/:id",
    INFRASTRUCURE_IMPORT: "/infrastructure/import",
    INFRASTRUCURE_BUILDER: "/infrastructure/builder/:id",
    DATAUPLOADS: "/data-uploads",
    DATADOWNLOAD: "/data-download",
    EMISSION_EVENTS: "/emission-events",
    SETTINGS_HOME: "/settings",
    SETTINGS_NOTIFICATIONS: "/settings/notifications",
    // Dashboard
    DASHBOARD: "/dashboard",
    DASHBOARD_THIRD_PARTY: "/dashboard/3rd-party",
    DASHBOARD_SELF_REPORTED: "/dashboard/self-reported",
    DASHBOARD_EPA_SEP: "/dashboard/epa-super-emitter",
    // Admin views
    ADMIN_DASHBOARD: "/admin",
    ADMIN_INFRASTRUCURE_IMPORT: "/admin/infrastructure/import",
    ADMIN_INFRASTRUCURE_IMPORT_APPROVAL_VIEW:
        "/admin/infrastructure/import/:id",
    ADMIN_DATA_IMPORT: "/admin/data-imports",
    ADMIN_STATS: "/admin/stats",
    ADMIN_DJANGO_ADMIN: "/admin/django-admin",
    // Static pages
    LICENSING: "/licensing",
    SUPPORT: "/support",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_OF_SERVICE: "/terms-of-service",
};
