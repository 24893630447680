/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EpaNotificationLocation } from './EpaNotificationLocation';
import {
    EpaNotificationLocationFromJSON,
    EpaNotificationLocationFromJSONTyped,
    EpaNotificationLocationToJSON,
} from './EpaNotificationLocation';
import type { FilterItemDetailGeometry } from './FilterItemDetailGeometry';
import {
    FilterItemDetailGeometryFromJSON,
    FilterItemDetailGeometryFromJSONTyped,
    FilterItemDetailGeometryToJSON,
} from './FilterItemDetailGeometry';

/**
 * Custom serializer that returns plume outlines for the map.
 * @export
 * @interface PlumeOulines
 */
export interface PlumeOulines {
    /**
     * 
     * @type {string}
     * @memberof PlumeOulines
     */
    readonly id: string;
    /**
     * 
     * @type {FilterItemDetailGeometry}
     * @memberof PlumeOulines
     */
    geometry?: FilterItemDetailGeometry;
    /**
     * 
     * @type {EpaNotificationLocation}
     * @memberof PlumeOulines
     */
    location: EpaNotificationLocation;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlumeOulines
     */
    readonly emissionRecordIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PlumeOulines
     */
    readonly hasPlume: boolean;
}

/**
 * Check if a given object implements the PlumeOulines interface.
 */
export function instanceOfPlumeOulines(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('location' in value)) return false;
    if (!('emissionRecordIds' in value)) return false;
    if (!('hasPlume' in value)) return false;
    return true;
}

export function PlumeOulinesFromJSON(json: any): PlumeOulines {
    return PlumeOulinesFromJSONTyped(json, false);
}

export function PlumeOulinesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlumeOulines {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'geometry': json['geometry'] == null ? undefined : FilterItemDetailGeometryFromJSON(json['geometry']),
        'location': EpaNotificationLocationFromJSON(json['location']),
        'emissionRecordIds': json['emission_record_ids'],
        'hasPlume': json['has_plume'],
    };
}

export function PlumeOulinesToJSON(value?: Omit<PlumeOulines, 'id'|'emission_record_ids'|'has_plume'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'geometry': FilterItemDetailGeometryToJSON(value['geometry']),
        'location': EpaNotificationLocationToJSON(value['location']),
    };
}

