import {
    ArrowPathIcon,
    ArrowUpTrayIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
    XCircleIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import { Card } from "../../ui/Card";
import UploadDropdown from "../UploadDropdown";
import { useMemo, useState } from "react";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    useAccountsApiClient,
    useAppSelector,
    useInfrastructureApiClient,
} from "../../hooks";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes";
import { loadCSVfromFile } from "../../utils/csv";
import { DetailView, DetailViewActions } from "../../ui/DetailView";
import { usBoundaries } from "../../constants/boundaries";
import * as turf from "@turf/turf";
import { wktToGeoJSON } from "betterknown";
import { CsvDataSridEnum } from "../../apiClient/generated";
import type { FeatureCollection } from "geojson";
import { isValidCoordinates } from "../../utils/geopatialUtils";
import { useQuery } from "@tanstack/react-query";
import { GeoJSONInfrastructurePreviewMiniMap } from "../MapV2/Minimaps";

const requiredColumns = [
    "Temp ID",
    "Site Name",
    "Type",
    "Equipment Type",
    "Name",
    "Latitude",
    "Longitude",
    "GeoJSON",
    "Geospatial filename",
    "Parent Temp ID",
    "Status",
    "Date of installation",
];

export const InfrastructureBuilderCsvTemplate = `Temp ID,Site Name,Type,Equipment Type,Name,Latitude,Longitude,GeoJSON,Geospatial filename,Parent Temp ID,Notes,Status,Date of installation
A100,Site A,SITE,,,,,"{""type"": ""Polygon"", ""coordinates"": [[[-103.41885214488084, 31.77510015971142 ], [-103.41824366168427, 31.773517254923185], [-103.41504304006999, 31.774427687130373], [-103.41580972889776, 31.77595884836162 ], [-103.41885214488084, 31.77510015971142 ]]]}",,,Any rows other than the previous ones will have their information stored along the infrastructure model., , 
A110,,EQUIPMENT GROUP,Tank Battery,,,,"{""type"":""Polygon"",""coordinates"":[[[-103.4180002684056,31.77496566558638],[-103.41786640210233,31.774562182037627],[-103.41752565151221,31.77465529378208],[-103.4176960268073,31.775048431224917],[-103.4180002684056,31.77496566558638]]]}",,A100,"Note: the Equipment Group A110 is located on side A100 (so the ""Parent Temp ID"" value is set to A100)", , 
A111,,EQUIPMENT,Tank,,31.774878000000001,-103.41793100000000,,,A110,"Tanks 1, 2, and 3 are inside tank group A110, so ""Parent Temp ID"" is A110.", , 
A112,,EQUIPMENT,Tank,,31.774799000000002,-103.41790399999999,,,A110,"There are 4 allowed values for the ""Type"" field: PIPELINE, SITE, EQUIPMENT GROUP, EQUIPMENT and EQUIPMENT COMPONENT. ", , 
A113,,EQUIPMENT,Tank,,31.77481300000000,-103.41783599999999,,,A110,, , 
A120,,EQUIPMENT,Compressor,,31.774843000000001,-103.41793900000000,,,A100,, , 
A121,,EQUIPMENT COMPONENT,Shut off valve,,,,,,A120,, , `;

interface ConfirmAndSubmitModalProps {
    visible: boolean;
    data?: any[];
    onClose: () => void;
    onSave: () => void;
}

const ConfirmAndSubmitModal = (props: ConfirmAndSubmitModalProps) => {
    const dataAsGeoJson = useMemo(() => {
        const features = [];
        if (props.data) {
            props.data.forEach((row) => {
                const lon = parseFloat(row["Longitude"]);
                const lat = parseFloat(row["Latitude"]);

                // Try to extract some data
                const properties = {
                    facility_name: row["Site Name"],
                    infra_type: (row["Type"] || "")
                        .replace(" ", " ")
                        .toUpperCase(),
                    equipment_type: row["Equipment Type"],
                };

                if (!Number.isNaN(lon) && !Number.isNaN(lat)) {
                    features.push({
                        type: "Feature",
                        properties,
                        geometry: {
                            type: "Point",
                            coordinates: [lon, lat],
                        },
                    });
                } else if (row["GeoJSON"]) {
                    let geojson;

                    // Try parsing as JSON
                    try {
                        geojson = JSON.parse(row["GeoJSON"]);
                    } catch {
                        // If that fails, parse it as WKT
                        try {
                            geojson = wktToGeoJSON(row["GeoJSON"]);
                        } catch {
                            console.log("Failed to parse geometry.");
                        }
                    }

                    // If parsing was successful, add to map.
                    if (
                        geojson &&
                        (geojson.type === "Polygon" ||
                            geojson.type === "Point" ||
                            geojson.type === "LineString")
                    ) {
                        features.push({
                            type: "Feature",
                            properties,
                            geometry: geojson,
                        });
                    }
                }
            });
        }
        return {
            type: "FeatureCollection",
            features,
        } as FeatureCollection<any>;
    }, [props.data]);

    return (
        <DetailView
            title="Preview points and submit*"
            visible={props.visible}
            onClose={props.onClose}
        >
            <div className="relative w-full h-[500px] overflow-hidden">
                <GeoJSONInfrastructurePreviewMiniMap geojson={dataAsGeoJson} />
            </div>
            <small>
                *shapes from extra files or columns aren't displayed on the
                preview
            </small>
            <DetailViewActions
                actions={[
                    {
                        action: props.onClose,
                        label: "Go back",
                    },
                    {
                        action: props.onSave,
                        label: "Submit file and process data",
                    },
                ]}
            />
        </DetailView>
    );
};

export const FileImport = () => {
    const navigate = useNavigate();

    // API Client
    const accountsApiClient = useAccountsApiClient();
    const apiClient = useInfrastructureApiClient();

    // Show additional options if user is staff
    const isStaff = useAppSelector((state) => state.auth.isStaff);
    const [selectedCompany, setSelectedCompany] = useState<number>();

    // File handling and flow control
    const [datum, setDatum] = useState<CsvDataSridEnum>(4326);
    const [csvFile, setCsvFile] = useState<File>();
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [csvData, setCsvData] = useState<any[]>();
    const [modalOpen, setModalOpen] = useState(false);
    const [geospatialFileNames, setGeospatialFileNames] = useState<string[]>(
        [],
    );
    const [geospatialFiles, setGeospatialFiles] = useState<File[]>([]);
    const [status, setStatus] = useState<
        {
            pass: boolean;
            warning?: boolean;
            message: string;
        }[]
    >([]);
    const checksPass = status.every((s) => s.pass);

    // Retrieve companies if user is staff
    const companiesQuery = useQuery({
        queryKey: ["staffCompanyList"],
        queryFn: async () => {
            return await accountsApiClient.accountsCompaniesList();
        },
        enabled: isStaff,
    });

    // Template CSV file
    const templateCsvContent =
        "data:text/csv;charset=utf-8," + InfrastructureBuilderCsvTemplate;
    const encodedUri = encodeURI(templateCsvContent);

    // Check CSV file
    const checkCsv = async (data: any[], error: boolean) => {
        // If failed to load file set error
        if (error) {
            setLoading(false);
            setLoadingMessage("");
            setStatus([
                {
                    message: "Failed to load CSV file.",
                    pass: false,
                },
            ]);
            return;
        }

        // Run checks on data
        const finalState = [];
        const additionalGeoSpatialFiles = [];

        // Check if there's any data
        let columns = [];
        if (data.length > 0) {
            columns = Object.keys(data[0]);
            finalState.push({
                pass: true,
                message: `Detected ${data.length} infrastructure item(s) to import.`,
            });

            // Check if all required columns are present
            const missingColumns = [];
            requiredColumns.forEach((col) => {
                if (!columns.includes(col.trim())) {
                    missingColumns.push(col);
                }
            });
            if (missingColumns.length > 0) {
                finalState.push({
                    pass: false,
                    message: `Missing required columns on header: ${missingColumns.join(
                        ", ",
                    )}.`,
                });
            } else {
                finalState.push({
                    pass: true,
                    message: "All required columns present.",
                });
            }

            // Check points
            const invalidCoordinates = [];
            const itemsOutsideUs = [];
            const usPolygon = turf.polygon(usBoundaries.geometry.coordinates);
            data.forEach((row) => {
                const lon = parseFloat(row["Longitude"]);
                const lat = parseFloat(row["Latitude"]);
                if (row["Longitude"] && row["Latitude"]) {
                    if (!isValidCoordinates(lat, lon)) {
                        invalidCoordinates.push(row["Temp ID"]);
                    } else if (
                        !turf.booleanPointInPolygon(
                            turf.point([lon, lat]),
                            usPolygon,
                        )
                    ) {
                        itemsOutsideUs.push(row["Temp ID"]);
                    }
                }
            });

            if (invalidCoordinates.length > 0) {
                finalState.push({
                    pass: false,
                    message: `Some points coordinates are outside valid bounds (±180 for longitude, ±90 for latitude): ${invalidCoordinates.join(
                        ", ",
                    )}.`,
                });
            } else {
                finalState.push({
                    pass: true,
                    message: "All points have valid coordinates.",
                });
            }

            if (itemsOutsideUs.length > 0) {
                finalState.push({
                    pass: true,
                    warning: true,
                    message: `Some points found outside continental US: ${itemsOutsideUs.join(
                        ", ",
                    )}.`,
                });
            } else {
                finalState.push({
                    pass: true,
                    message: "All points within continental US.",
                });
            }

            // Check if any external data files are required
            const unsupportedFiles = [];
            data.forEach((row) => {
                const geospatialFilename = (
                    row["Geospatial filename"] || ""
                ).trim();
                const fileExtension = geospatialFilename.split(".").pop();
                if (geospatialFilename !== "") {
                    if (["geojson", "kml"].includes(fileExtension)) {
                        additionalGeoSpatialFiles.push(
                            geospatialFilename.trim(),
                        );
                    } else {
                        unsupportedFiles.push(geospatialFilename.trim());
                    }
                }
            });

            if (additionalGeoSpatialFiles.length > 0) {
                finalState.push({
                    pass: true,
                    warning: true,
                    message: `Some external geospatial files (${additionalGeoSpatialFiles.length}) were referenced in the CSV.`,
                });
            }

            if (unsupportedFiles.length > 0) {
                finalState.push({
                    pass: true,
                    warning: true,
                    message: `The following external geospatial file extensions are not supported: ${unsupportedFiles.join(
                        ", ",
                    )}.`,
                });
            }
        } else {
            finalState.push({
                pass: false,
                message: "No data in CSV file",
            });
        }

        // Set state after validation
        setGeospatialFileNames(additionalGeoSpatialFiles);
        setGeospatialFiles([]);
        setStatus(finalState);
        setCsvData(data);
        setLoading(false);
        setLoadingMessage("");
    };

    // Callback to store file on state
    const onDropFiles = async (files: FileList) => {
        if (!files) return;

        // If a file was selected
        setCsvFile(files[0]);
        setLoading(true);
        setLoadingMessage("Loading CSV file...");

        // Load CSV and call validation function
        loadCSVfromFile({ file: files[0], callback: checkCsv });
    };

    const onDropExtraFiles = async (files: FileList) => {
        if (!files) return;

        // Check if files are present if filelist before accepting them.
        const extraFiles: File[] = [...geospatialFiles];
        [...files].forEach((file) => {
            if (
                geospatialFileNames.includes(file.name) &&
                !extraFiles.find((e) => e.name == file.name)
            ) {
                extraFiles.push(file);
            }
        });

        // Save to state
        setGeospatialFiles(extraFiles);
    };

    // Import action
    const createImport = async () => {
        setLoading(true);
        setModalOpen(false);
        try {
            // Create Import
            setLoadingMessage("[1/4] Creating import...");
            const response = await apiClient.infrastructureImportCreate({
                infrastructureImportRequest: {
                    csvDataSrid: datum,
                    csvInputFilename: csvFile.name,
                },
            });

            // Change company before starting uploads
            if (isStaff && selectedCompany) {
                await apiClient.infrastructureAdminImportPartialUpdate({
                    id: response.id,
                    patchedAdminInfrastructureImportRequest: {
                        owner: selectedCompany,
                    },
                });
            }

            // Upload CSV file
            setLoadingMessage("[2/4] Uploading CSV file...");
            const uploadUrlResponse =
                await apiClient.infrastructureImportUploadUrlCreate({
                    id: response.id,
                    infrastructureImportFileUploadRequest: {
                        filename: csvFile.name,
                    },
                });

            // Upload file and update upload status
            await axios.put(uploadUrlResponse.uploadUrl, csvFile, {
                headers: {
                    "Content-Type": csvFile.type,
                },
            });

            // Upload extra files.
            for (let i = 0; i < geospatialFiles.length; i++) {
                const fileToUpload = geospatialFiles[i];
                setLoadingMessage(
                    `[3/4] Uploading ${fileToUpload.name} file (${
                        i + 1
                    } out of ${geospatialFiles.length})...`,
                );
                const extraFileUploadUrl =
                    await apiClient.infrastructureImportUploadUrlCreate({
                        id: response.id,
                        infrastructureImportFileUploadRequest: {
                            filename: fileToUpload.name,
                        },
                    });

                // Upload file and update upload status
                await axios.put(extraFileUploadUrl.uploadUrl, fileToUpload, {
                    headers: {
                        "Content-Type": fileToUpload.type,
                    },
                });
            }

            // Signal backend it is clear to start processing the data
            setLoadingMessage("[4/4] Mark import as ready to process...");
            await apiClient.infrastructureImportStartProcessingCreate({
                id: response.id,
            });

            // Navigate to status page
            navigate(ROUTES.INFRASTRUCURE_BUILDER.replace(":id", response.id));
        } catch {
            setCsvFile(undefined);
            setGeospatialFileNames([]);
            setGeospatialFiles([]);
        }
        setLoading(false);
        setLoadingMessage("");
    };

    return (
        <>
            <Card>
                <h2>Data import</h2>
                <hr className="my-4" />
                <p className="text-base mb-2">
                    This tool assists you to import infrastructure information
                    into AerShed. It allows you to upload and preview data
                    before finalization and guides you on completion of any
                    missing information.
                </p>
                <p></p>

                <p className="text-base">
                    Please
                    <a
                        href={encodedUri}
                        className="mx-1 text-blue-700"
                        download="template.csv"
                    >
                        download the template CSV file
                    </a>
                    and follow our
                    <a href="#usage-guide" className="mx-1 text-blue-700">
                        usage guide
                    </a>
                    to get started.
                </p>
            </Card>

            <Card>
                <h2>Import CSV File</h2>
                <hr className="my-4" />
                {loading ? (
                    <>
                        <div className="flex items-center justify-center py-6">
                            <ArrowPathIcon className="w-10 h-10 animate-spin" />
                        </div>
                        {loadingMessage && (
                            <div className="flex items-center justify-center pb-2">
                                {loadingMessage}
                            </div>
                        )}
                    </>
                ) : csvFile ? (
                    <>
                        <div className="text-base">
                            <p className="mb-2 ">
                                <span className="font-bold">Filename:</span>{" "}
                                {csvFile.name}
                            </p>
                            <p className="font-bold">Data pre-checks:</p>
                            {status.map((s) => (
                                <p className="flex items-center">
                                    {s.pass ? (
                                        s.warning ? (
                                            <ExclamationTriangleIcon className="h-5 w-5 mr-2 text-yellow-500" />
                                        ) : (
                                            <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" />
                                        )
                                    ) : (
                                        <XCircleIcon className="h-5 w-5 mr-2 text-red-500" />
                                    )}
                                    {s.message}
                                </p>
                            ))}
                        </div>

                        {geospatialFileNames.length > 0 && (
                            <>
                                <hr className="my-4" />
                                <div className="text-base flex">
                                    <span className="pr-2">
                                        Please select the following geospatial
                                        files referenced in the CSV
                                        <sup>**</sup>:
                                        <ul className="list-inside mt-2">
                                            {geospatialFiles &&
                                                geospatialFileNames.map(
                                                    (filename) => {
                                                        return (
                                                            <li className="flex items-center">
                                                                {geospatialFiles.find(
                                                                    (e) => {
                                                                        return (
                                                                            e.name ==
                                                                            filename
                                                                        );
                                                                    },
                                                                ) ? (
                                                                    <CheckCircleIcon className="mr-2 w-5 h-5 text-green-600" />
                                                                ) : (
                                                                    <QuestionMarkCircleIcon className="mr-2 w-5 h-5 text-yellow-600" />
                                                                )}
                                                                {filename}
                                                            </li>
                                                        );
                                                    },
                                                )}
                                        </ul>
                                        <p className="text-sm mt-2">
                                            * Missing files will be ignored
                                            during processing.
                                        </p>
                                    </span>
                                    <UploadDropdown
                                        accept=".kml,.geojson"
                                        onChange={onDropExtraFiles}
                                        multiple
                                    />
                                </div>
                                <hr className="my-4" />
                            </>
                        )}

                        <div className="flex w-full justify-end mt-4 space-x-3">
                            {isStaff && (
                                <div className="flex items-center text-sm">
                                    <span className="font-bold">
                                        Company <sup>(staff only)</sup>:
                                    </span>
                                    <select
                                        className="rounded-lg ml-2 text-sm"
                                        value={selectedCompany}
                                        onChange={(e) =>
                                            setSelectedCompany(
                                                e.target
                                                    .value as unknown as number,
                                            )
                                        }
                                    >
                                        <option value={undefined}></option>
                                        {companiesQuery.data?.map((c) => (
                                            <option value={c.id}>
                                                {c.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <div className="flex items-center text-sm">
                                Using datum:
                                <select
                                    className="rounded-lg ml-2 text-sm"
                                    value={datum}
                                    onChange={(e) =>
                                        setDatum(
                                            e.target
                                                .value as unknown as CsvDataSridEnum,
                                        )
                                    }
                                >
                                    <option value={CsvDataSridEnum.NUMBER_4326}>
                                        WGS 84 / EPSG4326
                                    </option>
                                    <option
                                        value={CsvDataSridEnum.NUMBER_26717}
                                    >
                                        NAD 27 / EPSG26717
                                    </option>
                                    <option value={CsvDataSridEnum.NUMBER_4269}>
                                        NAD 83 / EPSG4269
                                    </option>
                                    <option value={CsvDataSridEnum.NUMBER_3857}>
                                        WGS 84 / Pseudo-Mercator / EPSG3857
                                    </option>
                                    <option
                                        value={CsvDataSridEnum.NUMBER_32661}
                                    >
                                        WGS 84 / UPS North / EPSG32661
                                    </option>
                                    <option
                                        value={CsvDataSridEnum.NUMBER_900913}
                                    >
                                        Google Maps Global Mercator / EPSG900913
                                    </option>
                                </select>
                            </div>

                            <button
                                onClick={() => {
                                    setGeospatialFileNames([]);
                                    setCsvFile(undefined);
                                    setCsvData(undefined);
                                    setGeospatialFiles([]);
                                    setStatus([]);
                                }}
                                className="flex items-center px-6 py-3 text-base bg-slate-200 rounded-lg hover:bg-slate-800 hover:text-white"
                            >
                                Replace file
                                <XMarkIcon className="w-5 h-5 ml-2" />
                            </button>
                            {checksPass && (
                                <button
                                    onClick={() => setModalOpen(true)}
                                    disabled={loading}
                                    className="flex items-center px-6 py-3 text-base bg-slate-200 rounded-lg hover:bg-slate-800 hover:text-white"
                                >
                                    Submit data
                                    <ArrowUpTrayIcon className="w-5 h-5 ml-2" />
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <UploadDropdown onChange={onDropFiles} accept=".csv" />
                )}
            </Card>
            <ConfirmAndSubmitModal
                visible={modalOpen}
                data={csvData}
                onSave={createImport}
                onClose={() => setModalOpen(false)}
            />
        </>
    );
};
