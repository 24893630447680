import {
    EpaOperatorNotification,
    SimpleInfrastructure,
} from "../../../apiClient/generated";
import { MapBase } from "../../MapV2/Map";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import { useEffect, useMemo, useState } from "react";
import { useMap } from "../../MapV2/hooks/mapState";
import { useMapApiClient } from "../../../hooks";
import { useQuery } from "@tanstack/react-query";
import {
    EMISSION_LAYER_STYLES,
    MAP_ZOOM_SHOW_DETAILS,
} from "../../MapV2/constants";
import {
    PlumeOutlinesLayer,
    EmissionImagesLayer,
    EmissionRecordsLayers,
} from "../../MapV2/layers/emissions";
import { PlumeOpacityPanel } from "../../MapV2/elements/DataControls/PlumeControl";
import { InfrastructureLayer } from "../../MapV2/layers/infrastructure";

export const EpaMinimap = ({
    epaNotification,
}: {
    epaNotification: EpaOperatorNotification;
}) => {
    const { flyTo } = useMap("EpaMinimap");
    const [showPlumes, setShowPlumes] = useState(true);
    const [plumeOpacity, setPlumeOpacity] = useState(0.2);
    const [oldPlumeOpacity, setOldPlumeOpacity] = useState(0);

    // Retrieve plume outlines and plume images
    const mapApiClient = useMapApiClient();
    const plumesQuery = useQuery({
        queryKey: ["plumes", epaNotification.id],
        queryFn: async () => {
            const response = await mapApiClient.mapPlumeImagesList({
                emission: [epaNotification.epaEmissionRecord],
            });
            return response.results;
        },
    });
    const outlinesQuery = useQuery({
        queryKey: ["outlines", epaNotification.id],
        queryFn: async () => {
            const response = await mapApiClient.mapPlumeOutlinesList({
                emission: [epaNotification.epaEmissionRecord],
            });
            return response.results;
        },
    });

    const sites = useMemo(() => {
        const s: SimpleInfrastructure[] = [];
        if (epaNotification.nearestSite) {
            s.push(epaNotification.nearestSite);
        }
        if (
            epaNotification.otherNearSites &&
            epaNotification.otherNearSites.length > 0
        ) {
            for (const site of epaNotification.otherNearSites) {
                s.push(site);
            }
        }
        return s;
    }, [epaNotification]);

    useEffect(() => {
        if (epaNotification.dataPoint) {
            const point = epaNotification.dataPoint.location;
            setTimeout(() => {
                flyTo(point.coordinates[1], point.coordinates[0], 15, true);
            }, 300);
        }
    }, [epaNotification, flyTo]);

    const layers = [
        PlumeOutlinesLayer(
            (outlinesQuery.data || []).filter(
                (p) => !showPlumes || (showPlumes && !p.hasPlume),
            ),
            "plume_outlines",
            true,
            MAP_ZOOM_SHOW_DETAILS + 1,
            plumeOpacity,
        ),
        EmissionImagesLayer(
            plumesQuery.data || [],
            "plume_images",
            showPlumes,
            MAP_ZOOM_SHOW_DETAILS + 1,
            plumeOpacity,
        ),
        InfrastructureLayer(
            createFeatureCollection(sites).features,
            undefined,
            true,
            16,
        ),
        EmissionRecordsLayers(
            createFeatureCollection([epaNotification.dataPoint]).features,
            true,
            MAP_ZOOM_SHOW_DETAILS + 1,
            "emission",
            EMISSION_LAYER_STYLES.epa,
        ),
    ];

    return (
        <div className="relative h-[400px] overflow-hidden rounded-lg border mb-4">
            <MapBase
                mapId="EpaMinimap"
                layers={[layers]}
                getTooltip={(info) => {
                    if (!info.object || !info.object.properties) {
                        return;
                    }
                    if ("equipmentType" in info.object.properties) {
                        return `${info.object.properties.name} (${info.object.properties.siteName})`;
                    }
                    if ("detectedRate" in info.object.properties) {
                        return "Emission";
                    }
                }}
                showMeasureControl
                showScaleControl
                showZoomControl
                showBasemapControl
            />

            <div className="absolute top-4 w-full flex justify-center">
                <PlumeOpacityPanel
                    className="w-3/4 overflow-x-scroll"
                    plumeOpacity={plumeOpacity}
                    showPlumes={showPlumes}
                    oldOpacity={oldPlumeOpacity}
                    onOpacityChange={setPlumeOpacity}
                    onToggleVisibility={(oldOpacity) => {
                        setPlumeOpacity(oldOpacity);
                        setOldPlumeOpacity(plumeOpacity);
                    }}
                    onShowPlumesChange={() => setShowPlumes(!showPlumes)}
                />
            </div>
        </div>
    );
};
