/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminInfrastructureImportItemLocation } from './AdminInfrastructureImportItemLocation';
import {
    AdminInfrastructureImportItemLocationFromJSON,
    AdminInfrastructureImportItemLocationFromJSONTyped,
    AdminInfrastructureImportItemLocationToJSON,
} from './AdminInfrastructureImportItemLocation';
import type { AdminInfrastructureImportItemPipelineShape } from './AdminInfrastructureImportItemPipelineShape';
import {
    AdminInfrastructureImportItemPipelineShapeFromJSON,
    AdminInfrastructureImportItemPipelineShapeFromJSONTyped,
    AdminInfrastructureImportItemPipelineShapeToJSON,
} from './AdminInfrastructureImportItemPipelineShape';
import type { AdminInfrastructureImportItemShape } from './AdminInfrastructureImportItemShape';
import {
    AdminInfrastructureImportItemShapeFromJSON,
    AdminInfrastructureImportItemShapeFromJSONTyped,
    AdminInfrastructureImportItemShapeToJSON,
} from './AdminInfrastructureImportItemShape';
import type { InfraStatusEnum } from './InfraStatusEnum';
import {
    InfraStatusEnumFromJSON,
    InfraStatusEnumFromJSONTyped,
    InfraStatusEnumToJSON,
} from './InfraStatusEnum';
import type { InfraTypeEnum } from './InfraTypeEnum';
import {
    InfraTypeEnumFromJSON,
    InfraTypeEnumFromJSONTyped,
    InfraTypeEnumToJSON,
} from './InfraTypeEnum';

/**
 * Infrastructure Import Item create serializer.
 * @export
 * @interface InfrastructureImportItemCreate
 */
export interface InfrastructureImportItemCreate {
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    placeholderId?: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    relatedImport: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    dataFilename?: string;
    /**
     * 
     * @type {any}
     * @memberof InfrastructureImportItemCreate
     */
    extraData?: any;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    siteName?: string;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    owner?: number;
    /**
     * 
     * @type {number}
     * @memberof InfrastructureImportItemCreate
     */
    parent?: number;
    /**
     * 
     * @type {AdminInfrastructureImportItemLocation}
     * @memberof InfrastructureImportItemCreate
     */
    location?: AdminInfrastructureImportItemLocation;
    /**
     * 
     * @type {AdminInfrastructureImportItemShape}
     * @memberof InfrastructureImportItemCreate
     */
    shape?: AdminInfrastructureImportItemShape;
    /**
     * 
     * @type {AdminInfrastructureImportItemPipelineShape}
     * @memberof InfrastructureImportItemCreate
     */
    pipelineShape?: AdminInfrastructureImportItemPipelineShape;
    /**
     * 
     * @type {InfraTypeEnum}
     * @memberof InfrastructureImportItemCreate
     */
    infraType: InfraTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InfrastructureImportItemCreate
     */
    equipmentType?: string;
    /**
     * 
     * @type {InfraStatusEnum}
     * @memberof InfrastructureImportItemCreate
     */
    infraStatus?: InfraStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof InfrastructureImportItemCreate
     */
    dateOfInstallation?: Date;
}

/**
 * Check if a given object implements the InfrastructureImportItemCreate interface.
 */
export function instanceOfInfrastructureImportItemCreate(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('relatedImport' in value)) return false;
    if (!('infraType' in value)) return false;
    return true;
}

export function InfrastructureImportItemCreateFromJSON(json: any): InfrastructureImportItemCreate {
    return InfrastructureImportItemCreateFromJSONTyped(json, false);
}

export function InfrastructureImportItemCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfrastructureImportItemCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'placeholderId': json['placeholder_id'] == null ? undefined : json['placeholder_id'],
        'relatedImport': json['related_import'],
        'dataFilename': json['data_filename'] == null ? undefined : json['data_filename'],
        'extraData': json['extra_data'] == null ? undefined : json['extra_data'],
        'siteName': json['site_name'] == null ? undefined : json['site_name'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'parent': json['parent'] == null ? undefined : json['parent'],
        'location': json['location'] == null ? undefined : AdminInfrastructureImportItemLocationFromJSON(json['location']),
        'shape': json['shape'] == null ? undefined : AdminInfrastructureImportItemShapeFromJSON(json['shape']),
        'pipelineShape': json['pipeline_shape'] == null ? undefined : AdminInfrastructureImportItemPipelineShapeFromJSON(json['pipeline_shape']),
        'infraType': InfraTypeEnumFromJSON(json['infra_type']),
        'name': json['name'] == null ? undefined : json['name'],
        'equipmentType': json['equipment_type'] == null ? undefined : json['equipment_type'],
        'infraStatus': json['infra_status'] == null ? undefined : InfraStatusEnumFromJSON(json['infra_status']),
        'dateOfInstallation': json['date_of_installation'] == null ? undefined : (new Date(json['date_of_installation'])),
    };
}

export function InfrastructureImportItemCreateToJSON(value?: Omit<InfrastructureImportItemCreate, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'placeholder_id': value['placeholderId'],
        'related_import': value['relatedImport'],
        'data_filename': value['dataFilename'],
        'extra_data': value['extraData'],
        'site_name': value['siteName'],
        'owner': value['owner'],
        'parent': value['parent'],
        'location': AdminInfrastructureImportItemLocationToJSON(value['location']),
        'shape': AdminInfrastructureImportItemShapeToJSON(value['shape']),
        'pipeline_shape': AdminInfrastructureImportItemPipelineShapeToJSON(value['pipelineShape']),
        'infra_type': InfraTypeEnumToJSON(value['infraType']),
        'name': value['name'],
        'equipment_type': value['equipmentType'],
        'infra_status': InfraStatusEnumToJSON(value['infraStatus']),
        'date_of_installation': value['dateOfInstallation'] == null ? undefined : ((value['dateOfInstallation'] as any).toISOString().substring(0,10)),
    };
}

