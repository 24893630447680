import { Popover } from "@headlessui/react";
import {
    AdminEmissionsRecordsStatsListProviderWithSourceParameterInner,
    EmissionStatusEnum,
    EventStatusEnum,
    InfraTypeEnum,
} from "../../apiClient/generated";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { FunnelIcon } from "@heroicons/react/24/outline";
import UploadDropdown from "../UploadDropdown";
import { ReactNode, useMemo, useState } from "react";
import { readGeoFileAsGeoJson } from "../../utils/geopatialUtils";
import { useAccountsApiClient, useDataProvidersApi } from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { PillButton } from "../../ui/Buttons";
import {
    LocationFilterState,
    RangeFilterState,
    TableDateFilterState,
} from "./state";
import { GeoFilterSelect } from "../filters/GeoFilterSelect";
import { useFloating, autoUpdate } from "@floating-ui/react";
import { offset, shift } from "@floating-ui/dom";
import { NumberField, ValidatingDateField } from "../../ui/Inputs";
import { DataProviderNestedFilter } from "../filters/ProviderFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";

interface BaseHeaderFilterProps {
    isFiltered: boolean;
    children: ReactNode;
}

const BaseHeaderFilter = (props: BaseHeaderFilterProps) => {
    const { refs, floatingStyles } = useFloating({
        strategy: "fixed",
        placement: "bottom-start",
        middleware: [
            offset({
                mainAxis: 20,
                crossAxis: -16,
            }),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
    });

    return (
        <div ref={refs.setReference}>
            <Popover>
                <Popover.Button className="flex items-center mr-2">
                    {props.isFiltered ? (
                        <FontAwesomeIcon icon={faFilterSolid} className="w-3" />
                    ) : (
                        <FontAwesomeIcon
                            icon={faFilter}
                            className="w-3 text-gray-400"
                        />
                    )}
                </Popover.Button>
                <Popover.Panel
                    ref={refs.setFloating}
                    style={floatingStyles}
                    className="min-w-56 overflow-auto rounded-md p-1 py-1 border-2 border-ae-slate-300 shadow-lg shadow-neutral-200 z-50 bg-white"
                >
                    {props.children}
                    <hr className="my-1" />
                    <div className="flex">
                        <Popover.Button className="w-full flex justify-center font-bold">
                            Close
                        </Popover.Button>
                    </div>
                </Popover.Panel>
            </Popover>
        </div>
    );
};

interface BaseTextSearchFilterProps {
    isFiltered: boolean;
    filterValue?: string;
    setFilterValue: (newFilterValue?: string) => void;
}

export const BaseTextSearchFilter = (props: BaseTextSearchFilterProps) => (
    <BaseHeaderFilter isFiltered={props.isFiltered}>
        <div className="p-1 font-normal text-sm text-ae-blue-900">
            <p>Search for:</p>
            <input
                autoFocus={true}
                type="text"
                className="text-sm py-1 px-2 rounded-md mt-1 w-full"
                value={props.filterValue}
                onChange={(e) => props.setFilterValue(e.target.value)}
            />
        </div>
    </BaseHeaderFilter>
);

interface MultipleChoiceFilterProps {
    isFiltered: boolean;
    filterValue?: string[];
    setFilterValue: (newFilterValue?: string[]) => void;
}

interface MultipleChoiceFilterBaseProps {
    props: MultipleChoiceFilterProps;
    options: {
        id: string;
        displayValue: string;
    }[];
    searchable?: boolean;
}

const MultipleChoiceFilterBase = ({
    props,
    options,
    searchable,
}: MultipleChoiceFilterBaseProps) => {
    const [search, setSearch] = useState("");

    const setFilter = (value) => {
        const values = props.filterValue || [];
        if (values.includes(value)) {
            values.splice(values.indexOf(value), 1);
            props.setFilterValue(values.length > 0 ? values : undefined);
        } else {
            values.push(value);
            props.setFilterValue(values);
        }
    };

    const selecedItems = options.filter((i) =>
        props.filterValue?.includes(i.id),
    );
    const unselecedItems = options.filter((i) => {
        if (search.trim() !== "") {
            return (
                !props.filterValue?.includes(i.id) &&
                i.displayValue.toLowerCase().includes(search.toLowerCase())
            );
        }
        return !props.filterValue?.includes(i.id);
    });

    return (
        <BaseHeaderFilter isFiltered={props.isFiltered}>
            <div className="p-1">
                {searchable && (
                    <input
                        autoFocus={true}
                        type="text"
                        className="text-sm py-1 px-2 rounded-md mt-1 w-full mb-2"
                        placeholder="Type to search users"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                )}
                {selecedItems.map((option, optionIdx) => {
                    const active = (props.filterValue || []).includes(
                        option.id,
                    );
                    return (
                        <label
                            key={optionIdx}
                            className={`relative cursor-default select-none 
                                flex gap-2 items-center px-2 py-1 rounded
                                ${
                                    active
                                        ? "bg-ae-slate-300"
                                        : "text-ae-slate-900"
                                }`}
                        >
                            <input
                                type="checkbox"
                                className="accent-ae-blue-500 focus:ring-0"
                                checked={active}
                                onChange={() => setFilter(option.id)}
                            />
                            <span
                                className={`block truncate capitalize ${
                                    active ? "font-bold" : "font-normal"
                                }`}
                            >
                                {option.displayValue}
                            </span>
                        </label>
                    );
                })}
                {unselecedItems.map((option, optionIdx) => {
                    const active = (props.filterValue || []).includes(
                        option.id,
                    );
                    return (
                        <label
                            key={optionIdx}
                            className={`relative cursor-default select-none 
                                flex gap-2 items-center px-2 py-1 rounded
                                ${
                                    active
                                        ? "bg-ae-slate-300"
                                        : "text-ae-slate-900"
                                }`}
                        >
                            <input
                                type="checkbox"
                                className="accent-ae-blue-500 focus:ring-0"
                                checked={active}
                                onChange={() => setFilter(option.id)}
                            />
                            <span
                                className={`block truncate capitalize ${
                                    active ? "font-bold" : "font-normal"
                                }`}
                            >
                                {option.displayValue}
                            </span>
                        </label>
                    );
                })}
            </div>
        </BaseHeaderFilter>
    );
};

const INFRA_OPTION_LIST = Object.values(InfraTypeEnum).map((item) => {
    return {
        id: item,
        displayValue: item.replaceAll("_", " ").toLowerCase(),
    };
});

export const InfraTypeFilter = (props: MultipleChoiceFilterProps) => {
    return (
        <MultipleChoiceFilterBase props={props} options={INFRA_OPTION_LIST} />
    );
};

interface ParentFilterProps {
    isFiltered: boolean;
    filterValue?: string;
    setFilterValue: (newFilterValue?: string) => void;
}

export const ParentFilter = (props: ParentFilterProps) => (
    <BaseHeaderFilter isFiltered={props.isFiltered}>
        {props.isFiltered ? (
            <div className="p-1 font-normal text-sm text-ae-blue-900">
                <p>Filtering by parent id:</p>
                <p className="truncate">{props.filterValue}</p>
                <hr className="my-1" />
                <Popover.Button
                    className="w-full flex justify-center font-bold"
                    onClick={() => props.setFilterValue(undefined)}
                >
                    Clear filter
                </Popover.Button>
            </div>
        ) : (
            <p className="p-1 font-normal text-ae-blue-900">
                Use the filter icon on the table rows to filter by parent.
                <FunnelIcon className="mt-1 w-4" />
            </p>
        )}
    </BaseHeaderFilter>
);

interface LocationFilterProps {
    isFiltered: boolean;
    filterValue?: any;
    setFilterValue: (newFilterValue?: any) => void;
}

export const LocationFilter = (props: LocationFilterProps) => {
    const [error, setError] = useState(false);

    const setLocationFilter = (files: FileList) => {
        // Load shape from upload
        readGeoFileAsGeoJson({
            file: files[0],
            callback: (data, error) => {
                if (error) {
                    setError(true);
                    return;
                }
                if (
                    data.type &&
                    ["Polygon", "LineString"].includes(data.type)
                ) {
                    props.setFilterValue(JSON.stringify(data));
                    setError(false);
                } else if (
                    data.geometry &&
                    ["Polygon", "LineString"].includes(data.geometry.type)
                ) {
                    props.setFilterValue(JSON.stringify(data.geometry));
                    setError(false);
                } else if (
                    data.type === "FeatureCollection" &&
                    data.features.length > 0
                ) {
                    props.setFilterValue(
                        JSON.stringify(data.features[0].geometry),
                    );
                    setError(false);
                } else {
                    setError(false);
                }
            },
        });
    };

    return (
        <BaseHeaderFilter isFiltered={props.isFiltered}>
            {props.isFiltered ? (
                <div className="p-1 font-normal text-sm text-ae-blue-900">
                    <p>Filtering using GeoJSON/KML filter.</p>
                    <hr className="my-1" />
                    <Popover.Button
                        className="w-full flex justify-center font-bold"
                        onClick={() => props.setFilterValue(undefined)}
                    >
                        Clear filter
                    </Popover.Button>
                </div>
            ) : (
                <div className="p-1 font-normal text-ae-blue-900">
                    <p className="mb-1">
                        Drop or select a KML or GeoJSON file containing a
                        polygon to filter the table.
                    </p>
                    {error && (
                        <p className="font-bold text-red-500 mb-1">
                            The selected file could not be parsed.
                        </p>
                    )}
                    <UploadDropdown
                        accept=".geojson,.kml"
                        onChange={setLocationFilter}
                    />
                </div>
            )}
        </BaseHeaderFilter>
    );
};

interface LocationFilterV2Props {
    isFiltered: boolean;
    filterValue?: LocationFilterState;
    setFilterValue: (newFilterValue?: LocationFilterState) => void;
}

export const LocationFilterV2 = (props: LocationFilterV2Props) => {
    return (
        <BaseHeaderFilter isFiltered={props.isFiltered}>
            <div className="w-[500px]">
                <GeoFilterSelect
                    selected={props.filterValue?.publicPresets}
                    onSelect={(selection) => {
                        if (selection && selection.length > 0) {
                            props.setFilterValue({
                                type: "location",
                                publicPresets: selection,
                            });
                        } else {
                            props.setFilterValue(undefined);
                        }
                    }}
                    customShape={props.filterValue?.geometry}
                    setCustomShape={(shape) => {
                        if (shape) {
                            props.setFilterValue({
                                type: "location",
                                geometry: shape,
                            });
                        } else {
                            props.setFilterValue(undefined);
                        }
                    }}
                />
            </div>
        </BaseHeaderFilter>
    );
};

const EMISSION_STATUS_LIST = Object.values(EmissionStatusEnum).map((item) => {
    return {
        id: item,
        displayValue: item.replaceAll("_", " ").toLowerCase(),
    };
});

export const EmissionStatusFilter = (props: MultipleChoiceFilterProps) => {
    return (
        <MultipleChoiceFilterBase
            props={props}
            options={EMISSION_STATUS_LIST}
        />
    );
};

export const AssignedToFilter = (props: MultipleChoiceFilterProps) => {
    const apiClient = useAccountsApiClient();
    const dataProvidersQuery = useQuery({
        queryKey: ["userOptionsFilter"],
        queryFn: async () => {
            return await apiClient.accountsUsersList();
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const providerOptions = useMemo(() => {
        if (!dataProvidersQuery.data) {
            return [];
        }
        return dataProvidersQuery.data.map((p) => {
            return {
                id: `${p.id}`,
                displayValue: `${p.firstName} ${p.lastName}`,
            };
        });
    }, [dataProvidersQuery.data]);

    return (
        <MultipleChoiceFilterBase
            props={props}
            options={providerOptions}
            searchable
        />
    );
};

export const ProviderFilter = (props: MultipleChoiceFilterProps) => {
    const apiClient = useDataProvidersApi();
    const dataProvidersQuery = useQuery({
        queryKey: ["dataProvidersWithData"],
        queryFn: async () => {
            return await apiClient.dataProvidersList({ hasData: true });
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const providerOptions = useMemo(() => {
        if (!dataProvidersQuery.data) {
            return [];
        }
        return dataProvidersQuery.data.results.map((p) => {
            return {
                id: p.id,
                displayValue: p.name,
            };
        });
    }, [dataProvidersQuery.data]);

    return <MultipleChoiceFilterBase props={props} options={providerOptions} />;
};

const EVENT_STATUS_LIST = Object.values(EventStatusEnum).map((item) => {
    return {
        id: item,
        displayValue: item.replaceAll("_", " ").toLowerCase(),
    };
});

export const EventStatusFilter = (props: MultipleChoiceFilterProps) => {
    return (
        <MultipleChoiceFilterBase props={props} options={EVENT_STATUS_LIST} />
    );
};

interface DateRangeFilterProps {
    isFiltered: boolean;
    filterValue?: TableDateFilterState;
    setFilterValue: (newFilterValue?: TableDateFilterState) => void;
}

export const DateRangeFilter = (props: DateRangeFilterProps) => (
    <BaseHeaderFilter isFiltered={props.isFiltered}>
        <div className="p-1 font-normal text-sm text-ae-blue-900">
            <div className="p-1 text-sm flex gap-2 w-80">
                <div className="w-1/2">
                    Start date:
                    <ValidatingDateField
                        value={props.filterValue?.after}
                        onChange={(c) =>
                            props.setFilterValue({
                                type: "date",
                                after: c,
                                before: props.filterValue?.before,
                            })
                        }
                    />
                </div>
                <div className="w-1/2">
                    End date:
                    <ValidatingDateField
                        value={props.filterValue?.before}
                        onChange={(c) =>
                            props.setFilterValue({
                                type: "date",
                                after: props.filterValue?.after,
                                before: c,
                            })
                        }
                    />
                </div>
            </div>
            {props.filterValue?.after > props.filterValue?.before ? (
                <p className="text-red-500 text-sm">
                    Start date must be before end date
                </p>
            ) : (
                <div className="grid grid-cols-2 gap-2 px-1 mt-1 whitespace-nowrap">
                    <PillButton
                        onClick={() => {
                            if (
                                !props.filterValue?.before ||
                                !props.filterValue?.after
                            ) {
                                return;
                            }
                            const onePeriod =
                                props.filterValue.before.getTime() -
                                props.filterValue.after.getTime();
                            const newStartDate = new Date(
                                props.filterValue.after.getTime() - onePeriod,
                            );
                            const newEndDate = new Date(
                                props.filterValue.before.getTime() - onePeriod,
                            );
                            props.setFilterValue({
                                type: "date",
                                after: newStartDate,
                                before: newEndDate,
                            });
                        }}
                    >
                        <ChevronLeftIcon className="h-3" />
                        Back 1 period
                    </PillButton>
                    <PillButton
                        onClick={() => {
                            if (
                                !props.filterValue?.before ||
                                !props.filterValue?.after
                            ) {
                                return;
                            }

                            const onePeriod =
                                props.filterValue.before.getTime() -
                                props.filterValue.after.getTime();
                            const newStartDate = new Date(
                                props.filterValue.after.getTime() + onePeriod,
                            );
                            const newEndDate = new Date(
                                props.filterValue.before.getTime() + onePeriod,
                            );
                            props.setFilterValue({
                                type: "date",
                                after: newStartDate,
                                before: newEndDate,
                            });
                        }}
                    >
                        Forward 1 period
                        <ChevronRightIcon className="h-3" />
                    </PillButton>
                </div>
            )}

            <hr className="my-2" />
            <div className="px-1 grid grid-cols-2 gap-2 text-sm mb-1">
                <PillButton
                    onClick={() => {
                        const today = new Date();
                        props.setFilterValue({
                            type: "date",
                            after: new Date(
                                new Date().setMonth(new Date().getMonth() - 1),
                            ),
                            before: today,
                        });
                    }}
                >
                    Last month
                </PillButton>
                <PillButton
                    onClick={() => {
                        const today = new Date();
                        props.setFilterValue({
                            type: "date",
                            after: new Date(
                                new Date().setMonth(new Date().getMonth() - 3),
                            ),
                            before: today,
                        });
                    }}
                >
                    Last 3 months
                </PillButton>
                <PillButton
                    onClick={() => {
                        const today = new Date();
                        props.setFilterValue({
                            type: "date",
                            after: new Date(
                                new Date().setMonth(new Date().getMonth() - 6),
                            ),
                            before: today,
                        });
                    }}
                >
                    Last 6 months
                </PillButton>
                <PillButton
                    onClick={() => {
                        props.setFilterValue(undefined);
                    }}
                >
                    Clear filter
                </PillButton>
            </div>
        </div>
    </BaseHeaderFilter>
);

interface RangeFilterProps {
    isFiltered: boolean;
    filterValue?: RangeFilterState;
    setFilterValue: (newFilterValue?: RangeFilterState) => void;
}

export const RangeFilter = (props: RangeFilterProps) => (
    <BaseHeaderFilter
        isFiltered={
            props.isFiltered &&
            (!!props.filterValue?.min || !!props.filterValue?.max)
        }
    >
        <div className="p-1 font-normal text-sm text-ae-blue-900">
            <div className="p-1 text-sm flex gap-2 w-60">
                <div className="w-1/2">
                    Minimum:
                    <NumberField
                        value={props.filterValue?.min}
                        onChange={(v: number) => {
                            props.setFilterValue({
                                type: "range",
                                min: v,
                                max: props.filterValue?.max,
                            });
                        }}
                        min={0}
                    />
                </div>
                <div className="w-1/2">
                    Maximum:
                    <NumberField
                        value={props.filterValue?.max}
                        onChange={(v: number) => {
                            props.setFilterValue({
                                type: "range",
                                min: props.filterValue?.min,
                                max: v,
                            });
                        }}
                        min={0}
                    />
                </div>
            </div>
            {props.filterValue?.min > props.filterValue?.max && (
                <div className="text-red-500 text-sm w-60 px-1">
                    Minimum value must be smaller than maximum value.
                </div>
            )}
        </div>
    </BaseHeaderFilter>
);

interface MultipleChoiceNestedFilterProps {
    isFiltered: boolean;
    filterValue?: {
        type: "provider";
        value: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    };
    setFilterValue: (newFilterValue?: {
        type: "provider";
        value: AdminEmissionsRecordsStatsListProviderWithSourceParameterInner[];
    }) => void;
}
export const DataProviderFilterV2 = ({
    isFiltered,
    filterValue,
    setFilterValue,
}: MultipleChoiceNestedFilterProps) => (
    <BaseHeaderFilter isFiltered={isFiltered}>
        <div className="w-[500px]">
            <DataProviderNestedFilter
                filterValue={filterValue?.value}
                setFilterValue={(newValue) =>
                    setFilterValue(
                        newValue
                            ? { type: "provider", value: newValue }
                            : undefined,
                    )
                }
            />
        </div>
    </BaseHeaderFilter>
);
