import { ReactNode } from "react";
import { GOOGLEMAPS_API_KEY, MAPTILER_API_KEY } from "../../constants/globals";
import { ImmutableLike, MapStyle } from "react-map-gl/dist/esm/types";

export const basemaps: {
    [key: string]: {
        mapStyle: string | MapStyle | ImmutableLike<MapStyle>;
        googleMaps?: boolean;
        displayName: string;
        attribution: string | ReactNode;
    };
} = {
    aershedCustom: MAPTILER_API_KEY
        ? {
              displayName: "Light",
              attribution: "© MapTiler © OpenStreetMap contributors",
              mapStyle: `https://api.maptiler.com/maps/3e651ff9-f4da-4941-bfd1-4ee449a5b28d/style.json?key=${MAPTILER_API_KEY}`,
          }
        : undefined,
    esri: {
        displayName: "ESRI",
        attribution: "Map tiles by ESRI",
        mapStyle: {
            version: 8,
            sources: {
                "basemap-tiles": {
                    type: "raster",
                    tiles: [
                        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                    ],
                    tileSize: 256,
                },
            },
            layers: [
                {
                    id: "basemap-tiles",
                    type: "raster",
                    source: "basemap-tiles",
                },
            ],
        },
    },
    googleMapsSatellite: GOOGLEMAPS_API_KEY
        ? {
              displayName: "Google",
              googleMaps: true,
              attribution: "© Google",
              mapStyle: "",
          }
        : undefined,
    mapTillerSatellite: MAPTILER_API_KEY
        ? {
              displayName: "MapTiller",
              attribution: "© MapTiler © OpenStreetMap contributors",
              mapStyle: {
                  version: 8,
                  sources: {
                      "basemap-tiles": {
                          type: "raster",
                          tiles: [
                              `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=${MAPTILER_API_KEY}`,
                          ],
                          tileSize: 256,
                      },
                  },
                  layers: [
                      {
                          id: "basemap-tiles",
                          type: "raster",
                          source: "basemap-tiles",
                      },
                  ],
              },
          }
        : undefined,
    usgs: {
        displayName: "US Geological Survey",
        attribution: "Tiles courtesy of the U.S. Geological Survey",
        mapStyle: {
            version: 8,
            sources: {
                "basemap-tiles": {
                    type: "raster",
                    tiles: [
                        "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
                    ],
                    tileSize: 256,
                },
            },
            layers: [
                {
                    id: "basemap-tiles",
                    type: "raster",
                    source: "basemap-tiles",
                },
            ],
        },
    },
    osm: {
        displayName: "Open Street Map",
        attribution: "OpenStreetMap contributors",
        mapStyle: {
            version: 8,
            sources: {
                "basemap-tiles": {
                    type: "raster",
                    tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
                    tileSize: 256,
                },
            },
            layers: [
                {
                    id: "basemap-tiles",
                    type: "raster",
                    source: "basemap-tiles",
                },
            ],
        },
    },
};

export type AvailableBasemaps = keyof typeof basemaps;
export type BasemapList = keyof typeof basemaps;
