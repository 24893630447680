/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginatedDataImportList,
} from '../models/index';
import {
    PaginatedDataImportListFromJSON,
    PaginatedDataImportListToJSON,
} from '../models/index';

export interface DataImportListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class DataImportApi extends runtime.BaseAPI {

    /**
     * Viewset for the DataImport model.
     */
    async dataImportListRaw(requestParameters: DataImportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedDataImportList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/data-import/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDataImportListFromJSON(jsonValue));
    }

    /**
     * Viewset for the DataImport model.
     */
    async dataImportList(requestParameters: DataImportListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedDataImportList> {
        const response = await this.dataImportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
