import { FileImport } from "./FileImport";
import { StepIndicator } from "./StepIndicator";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useInfrastructureApiClient } from "../../hooks";
import { WaitingAntivirusCheck } from "./WaitingAntivirusCheck";
import { WaitingDataImport } from "./WaitingDataImport";
import { VerifyAndPreview } from "./VerifyAndPreview";
import { WaitingApproval } from "./WaitingApproval";
import { WaitingLocationMatcher } from "./WaitingLocationMatcher";
import { WaitingLineDeduplicator } from "./WaitingLineDeduplicator";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface InfrastructureBuilderProps {
    infrastructureImportId: string;
}

export const InfrastructureBuilder = (props: InfrastructureBuilderProps) => {
    const apiClient = useInfrastructureApiClient();
    const queryClient = useQueryClient();
    const importDataQuery = useQuery({
        queryKey: ["infrastructureImport", props.infrastructureImportId],
        queryFn: async () => {
            return await apiClient.infrastructureImportRetrieve({
                id: props.infrastructureImportId,
            });
        },
        refetchOnWindowFocus: false,
        enabled: props.infrastructureImportId !== "new",
        staleTime: 0,
    });

    const refresh = () => {
        queryClient.invalidateQueries({
            queryKey: ["infrastructureImport", props.infrastructureImportId],
        });
        importDataQuery.refetch();
    };

    if (importDataQuery.isLoading && props.infrastructureImportId !== "new") {
        return (
            <div className="flex justify-center my-20">
                <ArrowPathIcon className="h-20 w-20 animate-spin" />
            </div>
        );
    }

    return (
        <div className="w-full">
            <StepIndicator
                step={importDataQuery.data?.importStatus}
                loading={false}
            />
            {!importDataQuery.data && <FileImport />}

            {importDataQuery.data &&
                importDataQuery.data.importStatus === "AV_CHECK" && (
                    <WaitingAntivirusCheck
                        importData={importDataQuery.data}
                        refresh={refresh}
                    />
                )}

            {importDataQuery.data &&
                importDataQuery.data.importStatus === "DATA_IMPORT" && (
                    <WaitingDataImport
                        importData={importDataQuery.data}
                        refresh={refresh}
                    />
                )}

            {importDataQuery.data &&
                importDataQuery.data.importStatus === "VERIFY_AND_COMPLETE" &&
                importDataQuery.data.matchingStatus !== "RUNNING" &&
                importDataQuery.data.deduplicationStatus !== "RUNNING" && (
                    <VerifyAndPreview
                        importData={importDataQuery.data}
                        refresh={refresh}
                    />
                )}

            {importDataQuery.data &&
                importDataQuery.data.importStatus === "VERIFY_AND_COMPLETE" &&
                importDataQuery.data.matchingStatus === "RUNNING" && (
                    <WaitingLocationMatcher
                        importData={importDataQuery.data}
                        refresh={refresh}
                    />
                )}

            {importDataQuery.data &&
                importDataQuery.data.importStatus === "VERIFY_AND_COMPLETE" &&
                importDataQuery.data.deduplicationStatus === "RUNNING" && (
                    <WaitingLineDeduplicator
                        importData={importDataQuery.data}
                        refresh={refresh}
                    />
                )}

            {importDataQuery.data &&
                importDataQuery.data.importStatus === "WAITING_REVIEW" && (
                    <WaitingApproval />
                )}
        </div>
    );
};
