import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AccountsApi, AuthApi } from "../apiClient";
import { Permissions } from "../apiClient/generated";
import * as Sentry from "@sentry/browser";

export const logInWithSession = createAsyncThunk(
    "auth/logInWithSession",
    async () => {
        const authenticatedApiClient = AccountsApi();
        const response =
            await authenticatedApiClient.accountsMembershipMyRetrieve();
        // Set sentry user
        Sentry.setUser({
            id: response.user.id,
            flags: response.flags.join(", "),
            isStaff: response.isStaff,
            isSuperuser: response.isSuperuser,
        });
        return {
            userId: response.user.id,
            email: response.user.email,
            firstName: response.user.firstName,
            lastName: response.user.lastName,
            permissions: response.permissions,
            flags: response.flags,
            isStaff: response.isStaff,
            isSuperuser: response.isSuperuser,
        };
    },
);

export const logOut = createAsyncThunk("auth/logOut", async () => {
    // Clear localStorage
    localStorage.removeItem("mapFilterState");
    localStorage.removeItem("mapEmissionState");
    localStorage.removeItem("mapInfrastructureState");
    // Clear sentry user
    Sentry.setUser(null);

    const apiClient = AuthApi();

    // Ignore 401 Unauthorized errors - the user is already logged out
    try {
        await apiClient.authLogoutCreate();
    } catch (error) {
        if (error?.status !== 401) {
            Sentry.captureException(error);
        }
    }

    return;
});

interface AuthState {
    loggedIn: boolean;
    loading: boolean;
    userId?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    token?: string;
    error?: string;
    permissions?: Permissions;
    isStaff?: boolean;
    isSuperuser?: boolean;
    flags?: string[];
}

const initialState = {
    loggedIn: false,
    loading: false,
} as AuthState;

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(logOut.fulfilled, (state) => {
            state.loading = false;
            state.loggedIn = false;
            state.token = undefined;
            state.userId = undefined;
            state.email = undefined;
            state.firstName = undefined;
            state.lastName = undefined;
        });
        builder.addCase(logInWithSession.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(logInWithSession.rejected, (state) => {
            state.loading = false;
            localStorage.removeItem("mapFilterState");
            localStorage.removeItem("mapEmissionState");
            localStorage.removeItem("mapInfrastructureState");
        });
        builder.addCase(logInWithSession.fulfilled, (state, action) => {
            state.loading = false;
            state.loggedIn = true;
            state.userId = action.payload.userId;
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.permissions = action.payload.permissions;
            state.flags = action.payload.flags;
            state.isStaff = action.payload.isStaff;
            state.isSuperuser = action.payload.isSuperuser;
        });
    },
});

export default authSlice.reducer;
