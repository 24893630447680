import { useAdminApiClient, useDataProvidersApi } from "../../hooks";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {
    AdminDataPointsStatsListRequest,
    AdminEmissionsRecordsStatsListRequest,
    OperatorStats,
} from "../../apiClient/generated";
import { DataTableV3 } from "../../components/DataTable/DataTableV3";
import { DataPointStats } from "../../apiClient/generated";
import { Tab } from "@headlessui/react";
import { Fragment, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { SecondaryButton } from "../../ui/Buttons";
import { ValidatingDateField } from "../../ui/Inputs";

const dataPointColumnHelper = createColumnHelper<DataPointStats>();
const dataPointStatsColumns = [
    dataPointColumnHelper.accessor("dataProvider", {
        id: "dataProvider",
        cell: (info) => info.getValue(),
        header: () => "Data Provider",
    }),
    dataPointColumnHelper.accessor("dataPointCnt", {
        id: "dataPointCnt",
        cell: (info) => info.getValue(),
        header: () => "Data Points",
    }),
    dataPointColumnHelper.accessor("plumeImageCnt", {
        id: "plumeImageCnt",
        cell: (info) => info.getValue(),
        header: () => "Plume Images",
    }),
];

const DataPointStatsTable: React.FC = () => {
    const apiClient = useAdminApiClient();

    const fetchFunction = async (props: AdminDataPointsStatsListRequest) => {
        return await apiClient.adminDataPointsStatsList(props);
    };

    return (
        <DataTableV3
            getRowId={(row) => row.dataProvider}
            dataName="dataPointsStats"
            extraFilters={{}}
            columns={dataPointStatsColumns}
            fetchFunction={fetchFunction}
            sortable={false}
            filterable={false}
        />
    );
};

const emissionRecordStatsColumnHelper = createColumnHelper<OperatorStats>();

const EmissionRecordPerCompanyStatsTable: React.FC = () => {
    const apiClient = useAdminApiClient();
    const providersApiClient = useDataProvidersApi();
    const [dateRange, setDateRange] = useState<{
        createdAtAfter?: Date;
        createdAtBefore?: Date;
    }>({});

    // Query filter groups
    const providersQuery = useQuery({
        queryKey: ["adminAllDataProviders"],
        queryFn: async () => {
            const response = await providersApiClient.dataProvidersList();
            return response.results;
        },
        staleTime: Infinity,
    });

    const columns = useMemo(() => {
        const cols: ColumnDef<any, any>[] = [
            emissionRecordStatsColumnHelper.accessor("operator", {
                id: "dataProvider",
                cell: (info) => info.getValue(),
                header: () => "Operator ID",
            }),
        ];

        if (providersQuery.data && providersQuery.data.length > 0) {
            for (const dataProvider of providersQuery.data) {
                cols.push(
                    emissionRecordStatsColumnHelper.display({
                        id: dataProvider.name,
                        header: () => dataProvider.name,
                        size: 120,
                        cell: (info) => {
                            const provider = info.row.original.stats.find(
                                (i) => i.provider === dataProvider.name,
                            );
                            return provider ? provider.count : 0;
                        },
                    }),
                );
            }
        }
        return cols;
    }, [providersQuery.data]);

    const fetchFunction = async (
        props: AdminEmissionsRecordsStatsListRequest,
    ) => {
        return await apiClient.adminEmissionsRecordsStatsList(props);
    };

    return (
        <div>
            <div className="p-4 flex gap-2 items-center">
                <span>Date range:</span>
                <div className="flex gap-2">
                    <ValidatingDateField
                        value={dateRange.createdAtAfter}
                        onChange={(c) =>
                            setDateRange((dr) => ({
                                ...dr,
                                createdAtAfter: c,
                            }))
                        }
                    />
                    <ValidatingDateField
                        value={dateRange.createdAtBefore}
                        onChange={(c) =>
                            setDateRange((dr) => ({
                                ...dr,
                                createdAtBefore: c,
                            }))
                        }
                    />
                </div>
                <span className="ml-2">Presets:</span>
                <SecondaryButton variant="sm" onClick={() => setDateRange({})}>
                    All time
                </SecondaryButton>
                <SecondaryButton
                    variant="sm"
                    onClick={() =>
                        setDateRange({
                            createdAtAfter: new Date(
                                Date.now() - 30 * 24 * 60 * 60 * 1000,
                            ),
                            createdAtBefore: new Date(),
                        })
                    }
                >
                    Last month
                </SecondaryButton>
                <SecondaryButton
                    variant="sm"
                    onClick={() =>
                        setDateRange({
                            createdAtAfter: new Date(
                                Date.now() - 90 * 24 * 60 * 60 * 1000,
                            ),
                            createdAtBefore: new Date(),
                        })
                    }
                >
                    Last 3 months
                </SecondaryButton>
            </div>
            <DataTableV3
                getRowId={(row) => row.operator}
                dataName="emissionRecordsStats"
                extraFilters={dateRange}
                columns={columns}
                fetchFunction={fetchFunction}
                sortable={false}
                filterable={false}
            />
        </div>
    );
};

export const AdminStatsPage = () => {
    return (
        <div className="container mx-auto px-6">
            <h1 className="mt-6 text-2xl mb-4">Platform Stats</h1>
            <Tab.Group>
                <Tab.List>
                    <div className="flex gap-1">
                        <Tab as={Fragment}>
                            {({ selected }) => (
                                <button
                                    className={`
                                        h-10 px-3 text-sm flex items-center justify-center
                                        border border-ae-gray-250 rounded-t -mb-[1px]
                                        ${
                                            selected
                                                ? "border-b-ae-gray-100 bg-white font-bold text-ae-blue-600"
                                                : "bg-neutral-50"
                                        }
                                    `}
                                >
                                    Data points
                                </button>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <button
                                    className={`
                                        h-10 px-3 text-sm flex items-center justify-center
                                        border border-ae-gray-250 rounded-t -mb-[1px]
                                        ${
                                            selected
                                                ? "border-b-ae-gray-100 bg-white font-bold text-ae-blue-600"
                                                : "bg-neutral-50"
                                        }
                                    `}
                                >
                                    Emissions per company
                                </button>
                            )}
                        </Tab>
                    </div>
                </Tab.List>
                <Tab.Panels>
                    <div className="bg-white rounded-b rounded-tr border border-ae-gray-300">
                        <Tab.Panel as={Fragment}>
                            <DataPointStatsTable />
                        </Tab.Panel>
                        <Tab.Panel>
                            <EmissionRecordPerCompanyStatsTable />
                        </Tab.Panel>
                    </div>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
