/* tslint:disable */
/* eslint-disable */
/**
 * Aerscape API
 * Aerscape API documentation (internal).
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RootCause,
} from '../models/index';
import {
    RootCauseFromJSON,
    RootCauseToJSON,
} from '../models/index';

export interface RootcausesListRequest {
    ordering?: string;
}

export interface RootcausesRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class RootcausesApi extends runtime.BaseAPI {

    /**
     * A viewset to retrieve Root Cause information.
     */
    async rootcausesListRaw(requestParameters: RootcausesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RootCause>>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/rootcauses/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RootCauseFromJSON));
    }

    /**
     * A viewset to retrieve Root Cause information.
     */
    async rootcausesList(requestParameters: RootcausesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RootCause>> {
        const response = await this.rootcausesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A viewset to retrieve Root Cause information.
     */
    async rootcausesRetrieveRaw(requestParameters: RootcausesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RootCause>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rootcausesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/api/v1/rootcauses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RootCauseFromJSON(jsonValue));
    }

    /**
     * A viewset to retrieve Root Cause information.
     */
    async rootcausesRetrieve(requestParameters: RootcausesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RootCause> {
        const response = await this.rootcausesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
